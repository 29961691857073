
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { tripActions } from "../../../redux/actions/trip_actions";
import moment from "moment";
import AlertDialog from "../../alert-dialog";
import ReturnTripForm from "./returnTripForm";

export default function ReturnTrip({ open }) {
    const [time, setTime] = useState(null)
    const [error, setError] = useState(false)
    const [isRequestByDropOff, setRequestByDropOff] = useState(false)
    const isPending = useSelector((state) => state.tripReducer.pending)

    const dispatch = useDispatch()
    const tripReducer = useSelector((state) => state.tripReducer)
    const customerReducer = useSelector((state) => state.customerReducer)
    const { tripToReturn } = tripReducer

    const onClose = () => {
        dispatch(tripActions.cleanRepeatTrip())
    }

    const combineDateWithTime = (date, time) => {
        let day = date.date();
        let month = date.month();
        let year = date.year();

        let hour = time.hour();
        let minute = time.minute();
        let second = time.second();

        return moment({ year, month, day, hour, minute, second });
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!time) {
            setError(true)
            return
        }
        setError(false)

        const prevTime = moment(tripToReturn.minPickUpTime)
        const newTime = moment(time)

        const combinedDate = combineDateWithTime(prevTime, newTime)

        const customerDateOfBirth = customerReducer.currentUser.birthDate || null;
        const customerName = (customerReducer.currentUser.name + ' ' + customerReducer.currentUser.surname1);

        const returnTrip = {
            customerId: tripToReturn.customerId,
            pickUpLocation: {
                address: tripToReturn.pickUpLocation.location.address,
                lat: tripToReturn.pickUpLocation.location.lat,
                lon: tripToReturn.pickUpLocation.location.lng,
            },
            dropOffLocation: {
                address: tripToReturn.dropOffLocation.location.address,
                lat: tripToReturn.dropOffLocation.location.lat,
                lon: tripToReturn.dropOffLocation.location.lng,
            },
            isRequestByDropOff: isRequestByDropOff,
            requestPickUpStartTime: combinedDate,
            pickupEndTime: moment(combinedDate).add(15, 'minutes'),
            numPassengers: tripToReturn.numPassengers,
            customerExpoPushToken: customerReducer.customerPushToken,
            customerNotificationLanguage: customerReducer.langNotification,
            luggage: parseInt(tripToReturn.luggage),
            hasWheelChair: tripToReturn.hasWheelChair,
            isOnRelatedCustomerBehalf: tripToReturn.isOnRelatedCustomerBehalf,
            customerDateOfBirth: customerDateOfBirth,
            customerName: customerName,
            relatedCustomer: tripToReturn.relatedCustomer,
            serviceLineDirection: null,
            isReturn: true,
            outboundTripId: tripToReturn.outboundTripId
        }

        dispatch(tripActions.getTripServices(
            returnTrip,
            tripToReturn.customerId,
            false,
            false,
        ))

        dispatch(tripActions.closeReturnTripModal())
    }

    const handleTimeChange = (time) => {
        setTime(time)
        setError(false)
    }

    return (
        <AlertDialog
            open={open}
            onClickCancel={onClose}
            onClickAccept={onSubmit}
            maxWidth={'xs'}
            fullWidth
            conditionDisableAccept={time == null || isPending}
            conditionDisableCancel={isPending}
            content={() =>
                <ReturnTripForm
                    time={time}
                    error={error}
                    setTime={handleTimeChange}
                    isRequestByDropOff={isRequestByDropOff}
                    setRequestByDropOff={setRequestByDropOff}
                    onSubmit={onSubmit}
                />}
        />

    )
}