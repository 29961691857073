import { Box, Checkbox, Grid, IconButton, Tooltip } from "@material-ui/core";
import React, { useCallback } from "react";
import { GridCenter, stylesMultipleTrip } from "../../details";
import i18next from "i18next";
import ErrorIcon from "@material-ui/icons/Error";
import { SERVICES_TYPE } from "../../../../../constants/types";
import CancelIcon from "@material-ui/icons/Cancel";
import ReplayIcon from "@material-ui/icons/Replay";
import { ArrowForward } from "../../icons/arrowForward";
import { ArrowBack } from "../../icons/arrowBack";

import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { isMultipleTripOutbound, outboundHasReturn } from "../../../tools";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ERROR_DATE = "0001-01-01T00:00:00";

export default function Item({
    trip,
    handleClickTrip,
    handleRetryTrip,
    handleMouseOver,
    handleCancelTrip,
    tripToPreview,
    selectedTrips,
    handleChangeSelectTrip,
    disableCheck,
    listOfTrips,
}) {
    const classes = stylesMultipleTrip();

    const serviceType = useSelector(
        (state) => state.multipleTripReducer.serviceType
    );

    const areInReturnProcess = useSelector(
        (state) => state.multipleTripReducer.areInReturnProcess
    );

    const checkPrintIcon = useCallback(
        (trip) => {
            if (trip.tripResponse == null) return false;
            return (
                outboundHasReturn(trip.tripResponse, listOfTrips) ||
                trip.tripResponse.isReturn
            );
        },
        [listOfTrips]
    );

    const hasReturnTripAndOutboundErrors = (trip, list) => {
        if (!trip.isReturn || !trip.hasErrors) return false;

        const outboundTrip = list.find(
            (item) =>
                item.tripId !== trip.tripId &&
                item.requestPickUpTime.split("T")[0] ===
                    trip.requestPickUpTime.split("T")[0]
        );

        if (outboundTrip == null) return false;
        return outboundTrip?.hasErrors;
    };

    const tripOutboundError = hasReturnTripAndOutboundErrors(trip, listOfTrips);

    const renderRetry = () => {
        if (
            SERVICES_TYPE.regularWithSchedule === serviceType &&
            trip.pickupHours?.length === 0
        )
            return false;

        return !tripOutboundError;
    };

    if (trip?.requestPickUpTime === ERROR_DATE) {
        return <></>;
    }

    return (
        <Grid
            key={trip.tripId}
            item
            md={12}
            sm={12}
            className={`${classes.divContainerTrip} ${classes.border} ${
                tripToPreview?.tripId === trip.tripId
                    ? `${classes.tripSelected}`
                    : ""
            }`}
            onClick={(event) => handleClickTrip(event, trip)}
        >
            <Grid item xl={3} md={2} className={`${classes.center}`}>
                {!outboundHasReturn(trip.tripResponse, listOfTrips) &&
                    !trip.tripResponse?.isReturn && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight={60}
                        >
                            <Checkbox
                                disabled={disableCheck(trip)}
                                checked={
                                    selectedTrips.find(
                                        (item) => item.id === trip.tripId
                                    )?.checked
                                }
                                onChange={(event) =>
                                    handleChangeSelectTrip(
                                        event,
                                        trip.tripResponse
                                    )
                                }
                                color="primary"
                            />
                        </Box>
                    )}
            </Grid>
            <GridCenter key={trip.tripId + "_date"}>
                <Grid item className={classes.colorPrimary}>
                    {checkPrintIcon(trip) &&
                        (isMultipleTripOutbound(trip.tripResponse)
                            ? ArrowForward()
                            : ArrowBack())}
                </Grid>
                <Grid item style={{ padding: 13 }}>
                    {moment(
                        trip.hasErrors
                            ? trip.requestPickUpTime
                            : trip.tripResponse.schedulePickUpTime
                    ).format("DD/MM/YYYY HH:mm")}
                </Grid>
            </GridCenter>
            <GridCenter key={trip.tripId + "_detail"}>
                {trip.hasErrors ? (
                    <>
                        <Tooltip
                            title={i18next.t(
                                "form.multipleBook.showErrorTooltip"
                            )}
                        >
                            <IconButton onClick={() => handleMouseOver(trip)}>
                                <ErrorIcon color="error" />
                            </IconButton>
                        </Tooltip>
                        {
                            // prevent retry when we dont't have options to choose and the service is regularWithSchedule
                            renderRetry() && (
                                <Tooltip
                                    title={i18next.t(
                                        "form.multipleBook.retryTripTooltip"
                                    )}
                                >
                                    <IconButton
                                        onClick={() => handleRetryTrip(trip)}
                                    >
                                        <ReplayIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </>
                ) : (
                    <>
                        <Tooltip
                            title={i18next.t(
                                "form.multipleBook.tripAcceptedTooltip"
                            )}
                        >
                            <IconButton>
                                <CheckCircleIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={i18next.t("form.multipleBook.delete")}>
                            <span>
                                <IconButton
                                    disabled={
                                        (moment(
                                            trip.tripResponse
                                                ?.schedulePickUpTime
                                        ).isBefore(moment()) ||
                                            areInReturnProcess) ??
                                        false
                                    }
                                >
                                    <CancelIcon
                                        onClick={() => handleCancelTrip(trip)}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                )}
            </GridCenter>
        </Grid>
    );
}

Item.propTypes = {
    trip: PropTypes.shape({
        tripId: PropTypes.number,
        tripResponse: PropTypes.object,
        hasErrors: PropTypes.bool,
        requestPickUpTime: PropTypes.string,
        pickupHours: PropTypes.array,
        isReturn: PropTypes.bool,
    }),
    handleClickTrip: PropTypes.func.isRequired,
    handleRetryTrip: PropTypes.func.isRequired,
    handleMouseOver: PropTypes.func.isRequired,
    handleCancelTrip: PropTypes.func.isRequired,
    tripToPreview: PropTypes.object,
    selectedTrips: PropTypes.array,
    handleChangeSelectTrip: PropTypes.func,
    disableCheck: PropTypes.func,
    listOfTrips: PropTypes.array.isRequired,
};
