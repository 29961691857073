export function getLocation(address) {
  const geocoder = new window.google.maps.Geocoder()
  return new Promise(function (resolve, reject) {
      geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === 'OK') {
              let location = results[0].geometry.location
              const loc_LatLng = new window.google.maps.LatLng(location.lat(), location.lng())
              resolve(loc_LatLng.toJSON());
          } else {
              reject(new Error('Couldnt\'t find the location ' + address));
          }
      })
  })

}

export function geocodeLatLng(lat, lng) {
  const geocoder = new window.google.maps.Geocoder();
  //const distanceMatrix = new window.google.maps.DistanceMatrixService();
  const latlng = {
    lat: lat,//parseFloat(latlngStr[0]),
    lng: lng//parseFloat(latlngStr[1]),
  };
  geocoder.geocode({ location: latlng }, (results, status) => {
    if (status === "OK") {
      if (results[0]) {
        //console.log("results => ", results)
      } else {
        window.alert("No results found");
      }
    } else {
      window.alert("Geocoder failed due to: " + status);
    }
  });
}


export function findLatLang(address) {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise(function (resolve, reject) {
    geocoder.geocode({ 'address': address }, function (results, status) {
      if (status === 'OK') {
        //console.log(results);
        resolve([results[0].geometry.location.lat(), results[0].geometry.location.lng()]);
      } else {
        reject(new Error('Couldnt\'t find the location ' + address));
      }
    })
  })
}

export function calculateTimePoints(origin, destination, mode) {
  const matrixDistance = new window.google.maps.DistanceMatrixService()
  return new Promise(function (resolve, reject) {
      matrixDistance.getDistanceMatrix({
          origins: [{ lat: origin[0], lng: origin[1] }],
          destinations: [{ lat: destination[0], lng: destination[1] }],
          travelMode: mode
      }, function (results, status) {
          if (status === 'OK') {
              let elements = results.rows[0].elements
              resolve(elements)
          } else {
              reject(new Error('Couldnt\'t find the location ' + origin + " " + destination));
          }
      })
  })
}
