import i18next from "i18next"
import { tripConstants } from "../../constants/trip_constants"
import { userActions } from "./user_actions"
import { alertActions } from "./alert_actions"
import { regularLineService } from "../../services/regularLineService"
import { tripActions } from "./trip_actions"


export const regularLine_actions = {
    getServiceLineStopsAvailable,
    getRegularLinePossibleDropoffs,
    getRegularLineStopScheduledHours,
    closeModalSelectScheduledPickUp
}

function closeModalSelectScheduledPickUp() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLOSE_MODAL_SELECT_SCHEDULE_PICKUP }
    }
}

function getServiceLineStopsAvailable(serviceId, lineId) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLineAvailableStops(serviceId, lineId).then(
            stops => {
                dispatch(successSingle(stops))

            },
            error => {
                if (error === 401) {
                    dispatch(userActions.refreshToken())
                    dispatch(getServiceLineStopsAvailable(serviceId))
                }
                dispatch(failureSingle(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST }
    }

    function successSingle(stops) {
        return { type: tripConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS, stops }
    }

    function failureSingle(error) {
        return { type: tripConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_FAILURE, error }
    }

}

function getRegularLinePossibleDropoffs(service, stopType, dataTripRequest, stop) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLinePossibleDropoffs(dataTripRequest.pickupStartTime, stop.id).then(
            stops => {
                dispatch(success(stops))
                dispatch(tripActions.setStops(service, stopType, stops, dataTripRequest))
                //dispatch(tripActions.openModalRequestTrip(i18next.t('trips.maps.markers.selectDestination')))
            },
            error => {
                if (error === 401) {
                    dispatch(userActions.refreshToken())
                    dispatch(getRegularLinePossibleDropoffs(service, stopType, dataTripRequest, stop))
                } else {
                    try {
                        let parsedError = JSON.parse(error)
                        let detailError = parsedError.detail
                        switch (detailError) {
                            case 'NoDropoffsInExpedition':
                                dispatch(alertActions.error(i18next.t('services.trips.requestTrip.NoDropoffsInExpedition')))
                                break
                            default:
                                dispatch(alertActions.error(i18next.t('services.services.serviceLine.error')))
                                break
                        }
                    }
                    catch (e) {
                        if (error != 403) dispatch(alertActions.error(error.toString()))
                    }

                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_REQUEST }
    }

    function success(stops) {
        return { type: tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_SUCCESS, stops }
    }

    function failure(error) {
        return { type: tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_FAILURE, error }
    }

}

function getRegularLineStopScheduledHours(requestDateTime, stopIdPickUp, stopIdDropOff, isRequestByDropOff) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLineStopScheduledHours(requestDateTime, stopIdPickUp, stopIdDropOff, isRequestByDropOff).then(
            expeditions => {
                dispatch(success(expeditions))
            },
            error => {
                if (error === 401) {
                    dispatch(userActions.refreshToken())
                    dispatch(getRegularLineStopScheduledHours(requestDateTime, stopIdPickUp, stopIdDropOff, isRequestByDropOff))
                } else {
                    try {
                        let parsedError = JSON.parse(error)
                        let detailError = parsedError.detail
                        switch (detailError) {
                            default:
                                dispatch(alertActions.error(i18next.t('services.services.serviceLine.error')))
                                break
                        }
                    }
                    catch (e) {
                        if (error != 403) dispatch(alertActions.error(error.toString()))
                    }

                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_REQUEST }
    }

    function success(expeditions) {
        return { type: tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS, expeditions }
    }

    function failure(error) {
        return { type: tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_FAILURE, error }
    }

}



