import { Box, Grid, MenuItem, Paper, TextField, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import Spinner from '../../shared/spinner';
import MultipleTrips from './multipleTrip';
import moment from "moment";
import i18next from 'i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import ResumeTripPage from '../resumeTrip/page'
import { useDispatch, useSelector } from 'react-redux';
import { tripActions } from '../../../redux/actions/trip_actions';
import MapView from '../../shared/map'
import { useTranslation } from 'react-i18next';

export const TripProperty = ({ children, ...props }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.tripProperty}>
            <div className={props.isBold ? classes.bold : ''} >
                {children}
            </div>
        </div >
    )
}

const ResumeTripWrappedMemo = (props) => {
    const classes = stylesMultipleTrip()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { multipleTripReducer, tripReducer } = props
    const [bounds, setBounds] = React.useState(null)
    const areasReducer = useSelector((state) => state.areasReducer)

    const isReturn = tripReducer?.trip ? tripReducer.trip.isReturn : false
    const hasReturn = tripReducer?.trip ? tripReducer.trip.hasReturn : false
    const displayMultiTrip = multipleTripReducer.executeMultipleTrips || ((isReturn || hasReturn) && tripReducer.results.length > 0)

    const setStops = useCallback((stops) => {
        dispatch(tripActions.setStops(stops))
    }, [dispatch])

    const [markers, setMarkers] = React.useState([])
    return (

        <Grid container spacing={2}>
            <Grid item md={displayMultiTrip ? 8 : 12} className={classes.defaultPadding}>
                <Paper elevation={1}>
                    <ResumeTripPage {...props} />
                    <Grid item md={12}>
                        <div style={{ position: 'relative', width: '100%', height: '80vh', zIndex: 0 }}>
                            <MapView
                                t={t}
                                google={props.google}
                                tripReducer={tripReducer}
                                areasReducer={areasReducer}
                                setStops={setStops}
                                markers={markers}
                                setMarkers={setMarkers}
                                bounds={bounds}
                                setBounds={setBounds}
                                {...props}
                            />
                        </div>
                    </Grid>
                </Paper>
            </Grid>
            {
                displayMultiTrip && (
                    <Grid item md={4} className={classes.defaultPadding}>
                        <Paper elevation={1}>
                            <MultipleTrips
                                {...props}
                            />
                        </Paper>
                    </Grid>
                )
            }
        </Grid>
    )

}

export const ResumeTripWrapped = React.memo(
    ResumeTripWrappedMemo,
    (prevProps, nextProps) => prevProps.tripReducer === nextProps.tripReducer && prevProps.multipleTripReducer.results === nextProps.multipleTripReducer.results
        && prevProps.multipleTripReducer.executeMultipleTrips === nextProps.multipleTripReducer.executeMultipleTrips
);


export const GridCenter = ({ children }) => {
    const classes = stylesMultipleTrip()
    return (
        <Grid item md={5} xl={4} className={`${classes.center}`}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={60}
            >
                {children}
            </Box>
        </Grid>
    )
}



export const ShowErrorComponent = ({ errors }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.errorContainer}>
            <Alert severity='error'>
                <AlertTitle><strong>{i18next.t('form.multipleBook.errorsOfTrip')}</strong></AlertTitle>
                <ul>
                    {
                        errors.map(item => <li key={item}>
                            {item?.detail}
                            {(item?.object?.nextAvailableTime)
                                && (
                                    `. ${i18next.t('services.trips.nextTimeAvailable')} ${moment(item?.object?.nextAvailableTime).format('HH:mm')}`
                                )
                            }
                        </li>)
                    }
                </ul>
            </Alert>
        </div>
    )
}


export const withListOfTrips = (Component) => {
    const classes = stylesMultipleTrip()
    return function (props) {
        if (props.listOfTrips?.length == 0)
            return (
                <Grid item md={12} className={classes.center}>
                    {i18next.t('form.multipleBook.emptyTrips')}
                </Grid>
            )
        return <Component {...props} />
    }
}

export const ErrorsMultipleRequestTripComponent = ({ errors }) => {
    const classes = stylesMultipleTrip()
    return (
        <div className={classes.errorContainer}>
            <Alert severity='error'>
                <AlertTitle><strong>{i18next.t('form.multipleBook.errorsOfTrip')}</strong></AlertTitle>
                <ul>
                    {
                        errors.map(item => <li key={item}>
                            {item?.detail}
                            {(item?.object?.nextAvailableTime)
                                && (
                                    `. ${i18next.t('services.trips.nextTimeAvailable')} ${moment(item?.object?.nextAvailableTime).format('HH:mm')}`
                                )
                            }
                        </li>)
                    }
                </ul>
            </Alert>
        </div>
    )
}

export const SelectPosibleScheduleComponent = ({ value, handleClickOption, expeditions, pending }) => {
    return (
        <div style={{ width: '100%', alignItems: 'center' }} >
            <TextField
                fullWidth
                value={value}
                variant="outlined"
                select
                label={i18next.t('form.modalSchedule.availableTimes')}
                onChange={(event) => handleClickOption(event.target.value)}
            >
                {expeditions?.map(option =>
                    <MenuItem
                        value={option.hour}
                        key={option.hour}
                    >
                        {option.hour}
                    </MenuItem>
                )}
            </TextField>
            <Spinner
                loading={pending}
            />
        </div>
    )
}

export const stylesMultipleTrip = makeStyles((theme) => ({
    container: {
        paddingTop: 30,
        paddingBottom: 75
    },
    containerSelectRequestType: {
        paddingTop: 40,
        paddingLeft: 20
    },
    colorPrimary: {
        color: theme.palette.primary.main,
        paddingTop: 5
    },
    center: {
        textAlign: 'center',
        padding: 10,
    },
    defaultPadding: {
        padding: theme.spacing(2)
    },
    divContainerTrip: {
        display: 'flex',
        cursor: 'pointer'
    },
    border: {
        borderBottom: '1px solid #E0E0E0',
        borderTop: '1px solid #E0E0E0'
    },
    tripProperty: {
        padding: 8,
        fontSize: '13.5px'
    },
    bold: {
        fontWeight: 'bold'
    },
    width100: {
        width: '100%'
    },
    errorContainer: {
        width: '100%',
        fontSize: theme.typography.fontSize,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    tripSelected: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderLeft: `8px solid ${theme.palette.primary.main}`
    }
}))
