import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

class GridExpedition extends React.Component {

    render() {
        return (
            <>
                {
                    Object.getOwnPropertyNames(this.props.entries).map(key =>
                        <React.Fragment key={Math.random()}>
                            <Grid item md={1}>
                                <div style={{ textAlign: 'center', fontSize: 17 }} >
                                    {this.props.daysOfWeek.find(x => x.id == key).name}
                                </div>
                                {
                                    this.props.entries[key].map(item =>
                                        <React.Fragment key={Math.random()}>
                                            <Grid item md={12} style={{ margin: 5, backgroundColor: '#80cae9', textAlign: 'center', fontSize: 17 }}>
                                                {item}
                                                {
                                                    !this.props.readOnly ?
                                                        <IconButton>
                                                            <CloseIcon onClick={() => this.props.removeItem(key, item)} style={{ margin: 0, position: 'absolute' }} />
                                                        </IconButton>
                                                        : null
                                                }
                                            </Grid>
                                        </React.Fragment>)
                                }
                            </Grid>
                        </React.Fragment>)
                }
            </>
        );
    }
}

export default GridExpedition;