import React from 'react';
import useStyles from './styles';
import { TextField, Button, Snackbar, FormControlLabel, Checkbox, Link, FormHelperText, Avatar, Typography, CssBaseline, Container } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MuiAlert from '@material-ui/lab/Alert';
import utils from '../../helpers/validations'
import WaitingScreen from '../custom/backdrop';
import TermsAndConditionsModal from './modal/termsAndConditionsModal';
import es from 'react-phone-input-2/lang/es.json'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'

class RegisterView extends React.Component {

    constructor(props) {
        super();
        this.state = {
            name: '',
            lastName: '',
            email: '',
            password: '',
            document: '',
            birthDate: '',
            phoneNumber: '',
            phoneNumberWithOutCode: '',
            prefix: '',
            countryCode: '',
            acceptRules: false,
            tosModal: false,
            errors: {
                name: { result: false, message: '' },
                lastName: { result: false, message: '' },
                email: { result: false, message: '' },
                password: { result: false, message: '' },
                document: { result: false, message: '' },
                birthDate: { result: false, message: '' },
                phoneNumber: { result: false, message: '' },
                acceptRules: { result: false, message: '' },
            }
        }
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.handleChangePhone = this.handleChangePhone.bind(this)
    }

    handleChange(event) {
        const { name, value } = event.target;
        let errors = this.state.errors;
        errors[name].message = "";
        this.setState({ [name]: value });
    }

    handleChangePhone(value, data) {
        this.setState({
            phoneNumber: value,
            phoneNumberWithoutCode: value.length > 0 ? value.slice(data.dialCode.length) : value,
            countryCode: data.countryCode,
            prefix: '+' + data.dialCode
        })
    }

    validateForm() {
        let errors = this.state.errors;
        errors.name = utils.validateName(this.state.name.trim())
        errors.lastName = utils.validateLastName(this.state.lastName.trim())
        errors.email = utils.validateEmail(this.state.email.trim())
        errors.password = utils.validatePassword(this.state.password.trim())
        errors.document = utils.validateID(this.state.document.trim())
        errors.birthDate = utils.required(this.state.birthDate)
        errors.phoneNumber = utils.validatePhone(this.state.phoneNumberWithOutCode, this.state.countryCode, this.state.prefix?.replace('+', ''))
        if (errors.birthDate.result) {
            errors.birthDate = utils.calculateAge(this.state.birthDate, 18)
        }
        errors.acceptRules = utils.validateRules(this.state.acceptRules)
        this.setState({ errors })
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.validateForm()
        if (this.isFormValid()) {
            this.props.register(this.state.email.trim(), this.state.password.trim(), this.state.name.trim(),
                this.state.lastName.trim(), this.state.document.trim(), this.state.acceptRules, this.state.birthDate, this.state.phoneNumberWithOutCode.trim(), this.state.prefix.trim(), this.state.countryCode.trim()
            );
        }
    }

    isFormValid() {
        let valid = true;
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result)
                valid = false
        });
        return valid
    }

    render() {
        const classes = this.props.styles
        const { t } = this.props
        return (
            <>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <WaitingScreen message={''} open={this.props.authenticationReducer.isSubmitting == true ? true : false} />
                    <div className={classes.container}>
                        <div className={classes.form}>
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <PersonIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    {t('register.page.title')}
                                </Typography>
                            </div>
                            <form className={classes.form} autoComplete="off" onSubmit={this.onSubmit} >
                                <TextField
                                    label={t('register.page.name') + '*'}
                                    variant="outlined"
                                    margin='normal'
                                    autoFocus
                                    onChange={this.handleChange}
                                    name="name"
                                    helperText={this.state.errors.name.message}
                                    error={this.state.errors.name.message.length === 0 ? false : true}
                                    value={this.state.name}
                                    fullWidth
                                />
                                <TextField
                                    label={t('register.page.lastName') + '*'}
                                    variant="outlined"
                                    margin='normal'
                                    onChange={this.handleChange}
                                    name="lastName"
                                    helperText={this.state.errors.lastName.message}
                                    error={this.state.errors.lastName.message.length === 0 ? false : true}
                                    value={this.state.lastName}
                                    fullWidth
                                />
                                <TextField
                                    label={t('register.page.email') + '*'}
                                    variant="outlined"
                                    margin='normal'
                                    onChange={this.handleChange}
                                    name="email"
                                    helperText={this.state.errors.email.message}
                                    error={this.state.errors.email.message.length === 0 ? false : true}
                                    type="email"
                                    value={this.state.email}
                                    fullWidth
                                />
                                <TextField
                                    label={t('register.page.document') + '*'}
                                    variant="outlined"
                                    margin='normal'
                                    onChange={this.handleChange}
                                    name="document"
                                    helperText={this.state.errors.document.message}
                                    error={this.state.errors.document.message.length === 0 ? false : true}
                                    value={this.state.document}
                                    fullWidth
                                />
                                <TextField
                                    label={t('register.page.birthDate') + '*'}
                                    variant="outlined"
                                    margin='normal'
                                    onChange={this.handleChange}
                                    type="date"
                                    helperText={this.state.errors.birthDate.message}
                                    error={this.state.errors.birthDate.message == '' ? false : true}
                                    name="birthDate"
                                    value={this.state.birthDate}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                                <PhoneInput
                                    autoFormat={false}
                                    country={"es"}
                                    localization={es}
                                    specialLabel={t('register.page.phone') + '*'}
                                    placeholder={t('register.page.phone') + '*'}
                                    inputStyle={{
                                        width: "100%", color: "rgba(0, 0, 0, 0.87)", fontSize: '0.8928571428571429rem', background: 'transparent'
                                    }}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.54)', font: 'inherit', fontSize: '0.75em',
                                        fontWeight: '400',
                                        background: 'transparent'
                                    }}
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChangePhone}
                                />
                                {this.state.errors.phoneNumber.message.length !== 0 && (<FormHelperText style={{ color: "red" }}>{this.state.errors.phoneNumber.message}</FormHelperText>)}
                                <TextField
                                    label={t('register.page.password') + '*'}
                                    variant="outlined"
                                    margin='normal'
                                    onChange={this.handleChange}
                                    type="password"
                                    helperText={this.state.errors.password.message}
                                    error={this.state.errors.password.message.length === 0 ? false : true}
                                    name="password"
                                    value={this.state.password}
                                    fullWidth
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.acceptRules}
                                            required
                                            disabled
                                            onChange={() => this.setState({
                                                acceptRules: !this.state.acceptRules
                                            })}
                                            color="primary"
                                        />
                                    }
                                    label={t('register.page.accept')}
                                />
                                <FormHelperText error>{this.state.errors.acceptRules.message}</FormHelperText>
                                <Link className={classes.linkRulesRegister} onClick={() => this.setState({ tosModal: true })}>
                                    {t('register.page.readTerms')}
                                </Link>

                                <div className={classes.button}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        size="large">
                                        {t('register.page.button')}
                                    </Button>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <Typography>{t('register.page.accountCreated')} <Link href='/login'>{t('register.page.login')}</Link> </Typography>
                                </div>
                            </form>
                        </div>
                        {this.props.alertReducer.message &&
                            <Snackbar
                                open={this.props.alertReducer.message ? true : false}
                                autoHideDuration={6000}
                                onClose={this.props.handleCloseAlert}
                            >
                                <MuiAlert
                                    elevation={6}
                                    variant="filled"
                                    onClose={this.props.handleCloseAlert}
                                    severity={this.props.alertReducer.type}
                                >
                                    {this.props.alertReducer.message}
                                </MuiAlert>
                            </Snackbar>
                        }
                    </div>
                </Container>
                <TermsAndConditionsModal
                    visible={this.state.tosModal}
                    handleClose={() => this.setState({ tosModal: false })}
                    acceptRules={() => this.setState({ acceptRules: true })}
                />

            </>
        );
    }
}

function Hook(props) {
    const classes = useStyles();
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.clear();

    }
    return <RegisterView
        {...props}
        handleCloseAlert={handleCloseAlert}
        styles={classes}
    />
}

export default Hook;