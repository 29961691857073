import React from 'react';
import { BallBeat } from 'react-pure-loaders';

const styleSpinner = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
const customStyleSpinner = {  position: "fixed", left: "50%", transform: "translate(-50%, -50%)" }
class Spinner extends React.Component {

    render() {
        return (
            <div style={this.props.centered == false ? customStyleSpinner : styleSpinner}>
                <BallBeat
                    loading={this.props.loading}
                    color={'#80cae9'}
                    size={50}
                />
            </div>
        );
    }
}

export default Spinner;