import { version } from '../../package.json'
const apiKey = process.env.REACT_APP_API_KEY_FIREBASE;
const stringConstants = {
    'URL_Refresh_Token': `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
    //'URL_ApiGateway': process.env.REACT_APP_BASE_URL,
    'URL_ApiGateway': window.location.origin != 'http://localhost:3000' ? window.location.origin.replace('clients', 'api') : process.env.REACT_APP_BASE_URL,
    'ID_Aplication': apiKey,
    'API_Key_Google': process.env.REACT_APP_API_KEY_GOOGLE,
    'URL_API_GEOCODE_GOOGLE': "https://maps.googleapis.com/maps/api/geocode/json?address=",
    'CUSTOMER_USER': "CustomerUser",
    'TIME_REFRESH_VEHICLE_POSITION': 3000,
    version: version
}
export default stringConstants;