import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';

export const SelectRequestType = ({ isRequestByDropOff, editable, onChange }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormLabel id="requestTypeLabel">{t('form.requestTripForm.requestType')}</FormLabel>
            <FormControl fullWidth>
                <RadioGroup
                    row
                    aria-labelledby='requestTypeLabel'
                    defaultValue="pickup"
                    name="requestType"
                    onChange={event => onChange(event.target.value === "dropoff")}
                >
                    <FormControlLabel value="pickup" control={<Radio color="primary" disabled={!editable} />} label={t('form.requestTripForm.pickup')} checked={!isRequestByDropOff} />
                    <FormControlLabel value="dropoff" control={<Radio color="primary" disabled={!editable} />} label={t('form.requestTripForm.dropoff')} checked={isRequestByDropOff} />
                </RadioGroup>
            </FormControl>
        </>
    )
}