import React from 'react';
import Spinner from '../shared/spinner';
import useStyles from './styles';
import { Grid, Hidden, TextField, Button, Snackbar, Avatar, Typography } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import MuiAlert from '@material-ui/lab/Alert';
import utils from '../../helpers/validations'

class ResetPasswordView extends React.Component {

    constructor(props) {
        super();
        this.state = {
            email: '',
            errors: {
                email: { result: false, message: '' }
            }
        }
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    handleChange(event) {
        const { name, value } = event.target;
        let errors = this.state.errors;
        errors[name].message = "";
        this.setState({ [name]: value });
    }

    validateForm() {
        let errors = this.state.errors;
        errors.email = utils.validateEmail(this.state.email)
        this.setState({ errors })
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.validateForm()
        if (this.isFormValid()) {
            this.props.forgotPassword(this.state.email)
        }

    }

    isFormValid() {
        let valid = true;
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result)
                valid = false
        });
        return valid
    }

    render() {
        const classes = this.props.styles
        const { t } = this.props
        return (
            <React.Fragment>
                <Spinner
                    loading={this.props.authenticationReducer.pending}
                />
                <div className={classes.container}>
                    <div className={classes.divForm} >
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <RestoreIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {t('resetPassword.page.title')}
                            </Typography>
                        </div>

                        <form autoComplete="off" onSubmit={this.onSubmit} >
                            <Grid className={classes.root} container spacing={2} justify="center">
                                <Grid item md={4} xs={1} implementation="css" smDown component={Hidden} />
                                <Grid item md={3} xs={1}>
                                    <TextField
                                        label={t('resetPassword.page.email')}
                                        variant="outlined"
                                        required
                                        autoFocus
                                        onChange={this.handleChange}
                                        name="email"
                                        type="email"
                                        helperText={this.state.errors.email.message}
                                        error={this.state.errors.email.message.length === 0 ? false : true}
                                        value={this.state.email}
                                    />
                                </Grid>
                                <Grid item md={4} xs={1} implementation="css" smDown component={Hidden} />

                                <Grid item md={4} xs={1} implementation="css" smDown component={Hidden} />
                                <Grid item md={3} xs={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        size="large">
                                        {t('resetPassword.page.button')}
                                    </Button>
                                </Grid>
                                <Grid item md={4} xs={1} implementation="css" smDown component={Hidden} />


                            </Grid>
                        </form>
                    </div>
                    {this.props.alertReducer.message &&
                        <Snackbar
                            open={this.props.alertReducer.message ? true : false}
                            autoHideDuration={3000}
                            onClose={this.props.handleCloseAlert}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={this.props.handleCloseAlert}
                                severity={this.props.alertReducer.type}
                            >
                                {this.props.alertReducer.message}
                            </MuiAlert>
                        </Snackbar>
                    }
                </div>

            </React.Fragment>
        );
    }
}

function Hook(props) {
    const classes = useStyles();
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.clear();

    }
    return <ResetPasswordView
        {...props}
        handleCloseAlert={handleCloseAlert}
        styles={classes}
    />
}

export default Hook;