import es from './default.json';
import en from './default.en.json';
import ca from './default.ca.json';

import alert_dialog_es from '../../components/alert-dialog/locales/default.json';
import alert_dialog_en from '../../components/alert-dialog/locales/default.en.json';
import alert_dialog_ca from '../../components/alert-dialog/locales/default.ca.json';
import bookings_es from '../../components/bookings/locales/default.json';
import bookings_en from '../../components/bookings/locales/default.en.json';
import bookings_ca from '../../components/bookings/locales/default.ca.json';
import home_es from '../../components/home/locales/default.json';
import home_en from '../../components/home/locales/default.en.json';
import home_ca from '../../components/home/locales/default.ca.json';
import register_es from '../../components/register/locales/default.json';
import register_en from '../../components/register/locales/default.en.json';
import register_ca from '../../components/register/locales/default.ca.json'
import login_es from '../../components/login/locales/default.json'
import login_en from '../../components/login/locales/default.en.json'
import login_ca from '../../components/login/locales/default.ca.json'
import profile_es from '../../components/profile/locales/default.json'
import profile_en from '../../components/profile/locales/default.en.json'
import profile_ca from '../../components/profile/locales/default.ca.json'
import resetpwd_es from '../../components/resetPassword/locales/default.json'
import resetpwd_en from '../../components/resetPassword/locales/default.en.json'
import resetpwd_ca from '../../components/resetPassword/locales/default.ca.json'
import dashboard_es from '../../components/dashboard/locales/default.json'
import dashboard_en from '../../components/dashboard/locales/default.en.json'
import dashboard_ca from '../../components/dashboard/locales/default.ca.json'
import constansts_es from '../../constants/locales/default.json'
import constansts_en from '../../constants/locales/default.en.json'
import constansts_ca from '../../constants/locales/default.ca.json'

import services_es from '../../services/locales/default.json'
import services_en from '../../services/locales/default.en.json'
import services_ca from '../../services/locales/default.ca.json'

import form_es from '../../components/trips/form/locales/default.json';
import form_en from '../../components/trips/form/locales/default.en.json';
import form_ca from '../../components/trips/form/locales/default.ca.json';

import maps_es from '../../components/trips/maps/locales/default.json'
import maps_en from '../../components/trips/maps/locales/default.en.json'
import maps_ca from '../../components/trips/maps/locales/default.ca.json'

import userTariff_es from '../../components/trips/userTariff/locales/default.json'
import userTariff_en from '../../components/trips/userTariff/locales/default.en.json'
import userTariff_ca from '../../components/trips/userTariff/locales/default.ca.json'

es['es']['alert-dialog'] = alert_dialog_es['es'];
en['en']['alert-dialog'] = alert_dialog_en['en'];
ca['ca']['alert-dialog'] = alert_dialog_ca['ca'];
es['es']['bookings'] = bookings_es['es'];
en['en']['bookings'] = bookings_en['en'];
ca['ca']['bookings'] = bookings_ca['ca'];
es['es']['home'] = home_es['es'];
en['en']['home'] = home_en['en'];
ca['ca']['home'] = home_ca['ca'];
es['es'].register = register_es['es'];
en['en'].register = register_en['en'];
ca['ca'].register = register_ca['ca'];
es['es'].login = login_es['es'];
en['en'].login = login_en['en'];
ca['ca'].login = login_ca['ca'];
es['es'].profile = profile_es['es'];
en['en'].profile = profile_en['en'];
ca['ca'].profile = profile_ca['ca'];
es['es'].resetPassword = resetpwd_es['es'];
en['en'].resetPassword = resetpwd_en['en'];
ca['ca'].resetPassword = resetpwd_ca['ca'];
es['es'].dashboard = dashboard_es['es'];
en['en'].dashboard = dashboard_en['en'];
ca['ca'].dashboard = dashboard_ca['ca'];
es['es'].material_table = constansts_es['es'];
en['en'].material_table = constansts_en['en'];
ca['ca'].material_table = constansts_ca['ca'];
es['es'].validations = constansts_es['es'].validations;
en['en'].validations = constansts_en['en'].validations;
ca['ca'].validations = constansts_ca['ca'].validations;
es['es']['form'] = form_es['es'];
en['en']['form'] = form_en['en'];
ca['ca']['form'] = form_ca['ca'];
es['es']['maps'] = maps_es['es'];
en['en']['maps'] = maps_en['en'];
ca['ca']['maps'] = maps_ca['ca'];
es['es'].services = services_es['es'].services;
en['en'].services = services_en['en'].services;
ca['ca'].services = services_ca['ca'].services;
es['es'].userTariff = userTariff_es['es']
en['en'].userTariff = userTariff_en['en']
ca['ca'].userTariff = userTariff_ca['ca']


export {
    es,
    en,
    ca,
};