export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    TOKEN_REQUEST: 'USERS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'USERS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

    REFRESH_REQUEST: 'USERS_REFRESH_REQUEST',
    REFRESH_SUCCESS: 'USERS_REFRESH_SUCCESS',
    REFRESH_FAILURE: 'USERS_REFRESH_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    INFO_REQUEST: 'USERS_INFO_REQUEST',
    INFO_SUCCESS: 'USERS_INFO_SUCCESS',
    INFO_FAILURE: 'USERS_INFO_FAILURE',

    RESETPSW_REQUEST: 'USERS_RESETPSW_REQUEST',
    RESETPSW_SUCCESS: 'USERS_RESETPSW_SUCCESS',
    RESETPSW_FAILURE: 'USERS_RESETPSW_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETBYID_REQUEST: 'USERS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    ADD_RELATEDCUSTOMER: 'USERS_ADD_RELATEDCUSTOMER',
    EDIT_RELATEDCUSTOMER: 'USERS_EDIT_RELATEDCUSTOMER',
    REMOVE_RELATEDCUSTOMER: 'USERS_REMOVE_RELATEDCUSTOMER',

    OPEN_MODAL: 'USERS_OPEN_MODAL',

    SETUSER_REQUEST: 'USERS_SETUSER_REQUEST',
    SETUSER_SUCCESS: 'USERS_SETUSER_SUCCESS',
    SETUSER_FAILURE: 'USERS_SETUSER_FAILURE',

    REMOVE_CUSTOMER_REQUEST: 'REMOVE_CUSTOMER_REQUEST',
    REMOVE_CUSTOMER_SUCCESS: 'REMOVE_CUSTOMER_SUCCESS',
    REMOVE_CUSTOMER_FAILURE: 'REMOVE_CUSTOMER_FAILURE',

    GET_PERSONAL_INFO_REQUEST: 'GET_PERSONAL_INFO_REQUEST',
    GET_PERSONAL_INFO_SUCCESS: 'GET_PERSONAL_INFO_SUCCESS',
    GET_PERSONAL_INFO_FAILURE: 'GET_PERSONAL_INFO_FAILURE',

    CUSTOMER_ADDRESSES_REQUEST: 'CUSTOMER_ADDRESSES_REQUEST',
    CUSTOMER_ADDRESSES_SUCCESS: 'CUSTOMER_ADDRESSES_SUCCESS',
    CUSTOMER_ADDRESSES_FAILURE: 'CUSTOMER_ADDRESSES_FAILURE',
};