import React from 'react';
import ResetPasswordView from './page';
import { userActions } from '../../redux/actions/user_actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { alertActions } from '../../redux/actions/alert_actions';

class ResetPassword extends React.Component {

    render() {
        return (
            <ResetPasswordView
                {...this.props}
            />
        );
    }
}

function mapState(state) {
    const { authenticationReducer, alertReducer } = state;
    return { authenticationReducer, alertReducer };
}

const actionCreators = {
    forgotPassword: userActions.forgotPassword,
    clear: alertActions.clear
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(ResetPassword)
