import { IconButton, Tooltip } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'


export const BackButton = ({ onClick }) => {
    return (
        <Tooltip title={i18next.t('form.requestTripForm.back')}>
            <IconButton onClick={onClick}>
                <KeyboardBackspaceIcon />
            </IconButton>
        </Tooltip>
    )
}