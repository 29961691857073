import React from 'react';
import LoginView from './page';
import { userActions } from '../../redux/actions/user_actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { compose } from 'redux';
import crypto from '../../helpers/crypto';
import { alertActions } from '../../redux/actions/alert_actions';
import { Redirect } from 'react-router-dom';
import Spinner from '../shared/spinner'
import { userService } from '../../services/userService';
import stringConstants from '../../constants/strings';

class Login extends React.Component {

    changeLanguage = (lng) => {
        this.setState({ lng: lng }, () => {
            i18next.changeLanguage(lng);
        });
    }

    componentDidMount() {
        let remember = localStorage.getItem("remember")
        if (remember != null) {
            let infoLogin = crypto.decryptObject(remember)
            this.props.login(infoLogin.loginId, infoLogin.password, infoLogin.remember)
        }
    }

    render() {
        const { authenticationReducer } = this.props
        return (
            <React.Fragment>
                {
                    authenticationReducer.pending == true ?
                        <Spinner loading={authenticationReducer.pending} />
                        :
                        userService.getWithExpiry('user_customer') && userService.getWithExpiry('userRole') == stringConstants.CUSTOMER_USER ?
                            <Redirect to="/home" />
                            :
                            <LoginView
                                {...this.props}
                                changeLanguage={this.changeLanguage}
                            />
                }
            </React.Fragment>

        )
    }
}

function mapState(state) {
    const { authenticationReducer, alertReducer } = state;
    return { authenticationReducer, alertReducer };
}

const actionCreators = {
    login: userActions.login,
    clear: alertActions.clear
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Login)