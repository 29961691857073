import areasConstants from "../../constants/areas_constants";
import { areasServices } from "../../services/areasService";
import { userActions } from "./user_actions"
import * as Time from "../../constants/time";
import geofencing from "../../helpers/geofencing";
import { userService } from "../../services/userService";

export const areasActions = {
    getZonesByType
}

function getZonesByType() {
    return dispatch => {
        dispatch(request())
        areasServices.getZonesByType().then(
            areas => {
                let arrayGeofencing = []
                areas.map((zone, index) => {
                    arrayGeofencing.push({ points: geofencing.generatePolygon(zone.points), pickupAndDropOffAutoComplete: zone.pickupAndDropOffAutoComplete, stops: zone.stops, id: zone.id, name: zone.name });
                })
                userService.setWithExpiry("zones", areas, Time.INFINITE);
                dispatch(success(areas, arrayGeofencing));
            },
            error => {
                if (error === 401) {
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(getZonesByType())
                    }, 2000);
                } else {
                    dispatch(failure(error));
                }
            }
        )
    }

    function request() {
        return { type: areasConstants.GET_ZONES_BY_TYPE_REQUEST }
    }
    function success(data, array) {
        return { type: areasConstants.GET_ZONES_BY_TYPE_SUCCESS, data, array }
    }
    function failure(error) {
        return { type: areasConstants.GET_ZONES_BY_TYPE_FAILURE, error }
    }
}