import MultipleBookPage from "./page"
import React, { useEffect, useState } from 'react';
import moment from "moment";
import i18next from "i18next";
import { withSpinner } from "./dayButton";
import AlertDialog from "../../alert-dialog";
import { useDispatch, useSelector } from "react-redux";
import { multipleTripsActions } from "../../../redux/actions/multipleTrips_action";



const MultipleBook = ({ weekDays }) => {

    const [daysOfWeek, setDaysOfWeek] = useState(Array(7).fill(false))
    const [startDate] = useState(moment().toDate())
    const [endDate, setEndDate] = useState(startDate)
    const dispatch = useDispatch()

    const multipleTripReducer = useSelector((state) => state.multipleTripReducer)
    const open = useSelector((state) => state.multipleTripReducer.openModalSelectFrecuency)
    const isPending = multipleTripReducer.pending
    const tripIdToPlan = multipleTripReducer.tripIdToPlanOutbound
    const requestPickUpStartTime = useSelector((state) => state.multipleTripReducer.requestPickUpStartTime)
    const isTripReturn = useSelector((state) => state.tripReducer.isReturn)

    const setDayOffWeek = (dayOffWeek) => {
        const newSetDaysOfWeek = [...daysOfWeek]
        newSetDaysOfWeek[dayOffWeek] = !daysOfWeek[dayOffWeek]
        setDaysOfWeek(newSetDaysOfWeek)
    }

    useEffect(() => {
        setDaysOfWeek(Array(7).fill(false))
    }, [open])

    useEffect(() => {
        if (requestPickUpStartTime) {
            const dateTimeOfTrip = moment(requestPickUpStartTime)
            const today = moment()
            setEndDate(moment.max(today, dateTimeOfTrip).add(1, 'week').toDate())
        }
    }, [requestPickUpStartTime])

    const handleAccept = () => {
        const daysSelected = daysOfWeek.map((item, i) => item ? i : false).filter(item => item !== false)
        const body = {
            tripId: tripIdToPlan,
            weekDays: daysSelected,
            endDate: endDate.toISOString(),
            lang: i18next.language
        }
        isTripReturn
            ? dispatch(multipleTripsActions.processMultipleTripOutboundAndReturn(body))
            : dispatch(multipleTripsActions.processMultipleTrip(body))
    }

    const MultipleBook = withSpinner(MultipleBookPage)

    return (
        <AlertDialog
            open={open}
            maxWidth={'xs'}
            onClickCancel={() => dispatch(multipleTripsActions.setOpenModalSelectFrecuency(false, null, null, null))}
            onClickAccept={() => handleAccept()}
            fullWidth
            conditionDisableCancel={multipleTripReducer.pending}
            conditionDisableAccept={!daysOfWeek.some(item => item === true) || isPending}
            title={i18next.t('form.multipleBook.title')}
            content={() =>
                <MultipleBook
                    weekDays={weekDays}
                    isPending={isPending}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setDayOffWeek={setDayOffWeek}
                    daysOfWeek={daysOfWeek}
                    minDate={startDate}
                />}

        />
    )
}

export default MultipleBook