import { multipleTripConstants } from '../../constants/multipleTrip_constants';
import { tripConstants } from '../../constants/trip_constants';

let initialState = {
    trip: null,
    error: null,
    markers: [],
    originStop: null,
    destinationStop: null,
    isSubmitting: false,
    bookings: [],
    modalSelectedService: false,
    selectedService: null,
    services: [],
    hasSelectedMyCustomAddress: false,
    showStops: false,
    stopsType: null,
    modalMessage: false,
    hasSelectedOriginStop: false,
    selectedOriginStop: null,
    selectedDestinationStop: null,
    originalData: null,
    showResume: false,
    repeatTrip: false,
    requestTripError: false,
    weekDays: [],
    isReturn: false,
    modalExpeditions: false,
    modalSelectScheduledPickUp: false,
    posibleSchedule: [],
    typesTrips: [],
    results: []
};

export function tripReducer(state = initialState, action) {
    switch (action.type) {
        case tripConstants.RESET_DATA_TRIP_REDUCER_SUCCESS:
            return {
                ...state,
                trip: null,
                error: null,
                isSubmitting: false,
                bookings: null,
                modalSelectedService: false,
                selectedService: null,
                services: [],
                hasSelectedMyCustomAddress: false,
                showStops: false,
                stopsType: null,
                modalMessage: false,
                hasSelectedOriginStop: false,
                selectedOriginStop: null,
                selectedDestinationStop: null,
                modalReturnTrip: false,
                tripToReturn: null,
                originalData: null,
                showResume: false,
                dropOffLocation: null,
                pickUpLocation: null,
                numPassengers: null,
                repeatDataObject: null,
                modifyTripData: null,
                isModifyTrip: false,
                modifyNewTrip: null,
                modifyPendingAction: false,

            }
        case tripConstants.ADD_TRIP_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                error: null,
            }
        case tripConstants.ADD_TRIP_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                trip: action.data,
                stopsType: null,
                showStops: false,
                error: null,
                showResume: true,
                modalSelectScheduledPickUp: false,
            }
        case tripConstants.ADD_TRIP_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                isReturn: false,
                trip: state.backupTrip,
                originStop: null,
                error: action.error
            }
        case tripConstants.GET_SERVICES_FROM_TRIP_REQUEST:
            const backupTrip = state.trip
            return {
                ...state,
                isSubmitting: true,
                error: null,
                showResume: false,
                originDistanceTime: null,
                destinationDistanceTime: null,
                trip: null,
                backupTrip: backupTrip,
            }

        case tripConstants.CLEAR_MARKER:
            return {
                ...state,
                markers: state.markers.filter(
                    item => item.name != action.index,
                ),
            }
        case tripConstants.CLEAR_ALL_MARKER:
            return {
                ...state,
                markers: [],
                modalSelectedService: false,
                originStop: null,
                destinationStop: null,
                services: [],
                selectedService: null,
                requestTripError: false,
                stopsType: null,
                stopList: [],
            }
        case tripConstants.GET_SERVICES_FROM_TRIP_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                services: action.data,
                selectedService: action.selectedService,
                dataTripRequest: action.dataTripRequest,
                modalSelectedService: action.modalSelectedService,
                originalData: action.dataTripRequest,
                error: null,
            }
        case tripConstants.GET_SERVICES_FROM_TRIP_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error,
                trip: state.backupTrip
            }
        //*** */
        case tripConstants.SELECT_SERVICE_TRIP_CLOSE:
            return {
                ...state,
                modalSelectedService: false
            };
        /*** */
        case tripConstants.GET_TRIPS_CUSTOMER_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                error: null
            }
        case tripConstants.GET_TRIPS_CUSTOMER_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                bookings: action.data,
                error: null
            }
        case tripConstants.GET_TRIPS_CUSTOMER_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error
            }
        case tripConstants.SET_TRIP_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                trip: action.data,
                error: null,
                showResume: true
            }
        case tripConstants.SET_TRIP_REJECTED_BY_USER_REQUEST:
            return {
                ...state,
                isSubmitting: true,

                error: null
            };
        case tripConstants.SET_TRIP_REJECTED_BY_USER_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                trip: null,
                error: null
            };
        case tripConstants.SET_TRIP_REJECTED_BY_USER_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error
            };
        case tripConstants.SET_TRIP_CANCEL_BY_USER_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                refresh: false,
                error: null
            };
        case tripConstants.SET_TRIP_CANCEL_BY_USER_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                refresh: true,
                error: null
            };
        case tripConstants.SET_TRIP_CANCEL_BY_USER_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                refresh: false,
                error: action.error
            };
        case tripConstants.SET_TRIP_ACCEPTED_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                error: null
            };
        case tripConstants.SET_TRIP_ACCEPTED_SUCCESS:
            return {
                ...state,
                trip: action.data,
                isSubmitting: false,
                error: null,
                showResume: true
            };
        case tripConstants.SET_TRIP_ACCEPTED_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error
            };
        case tripConstants.ACCEPT_MODIFY_TRIP_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                error: null
            }
        case tripConstants.ACCEPT_MODIFY_TRIP_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                error: null,
                showResume: true
            }
        case tripConstants.ACCEPT_MODIFY_TRIP_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error,
                showStops: false,
                stopsType: null
            }
        // PER MOSTRAR PARADES AL MAPA
        case tripConstants.SET_ORIGIN_STOPS_LIST_SUCCESS:
            return {
                ...state,
                stopList: action.stopList,
                selectedService: action.selectedService,
                showStops: true,
                stopsType: 'origin',
            }
        case tripConstants.SET_DESTINATION_STOPS_LIST_SUCCESS:
            return {
                ...state,
                stopList: action.stopList,
                selectedService: action.selectedService,
                showStops: true,
                stopsType: 'destination',
            }
        // MODAL INFO PARADES
        case tripConstants.OPEN_MODAL_REQUEST_TRIP:
            return {
                ...state,
                modalMessage: true,
                message: action.message
            };
        case tripConstants.CLOSE_MODAL_REQUEST_TRIP:
            return {
                ...state,
                modalMessage: false,
            };
        // *********************
        // SELECTED STOP FROM REQUEST TRIP ON MAP
        case tripConstants.SET_SELECTED_MY_CUSTOM_ADDRESS_SUCCESS:
            return {
                ...state,
                hasSelectedMyCustomAddress: action.bool //true or false options
            }
        case tripConstants.SET_SELECTED_STOP_ORIGIN_SUCCESS:
            return {
                ...state,
                dataTripRequest: action.newDataTripRequest,
                hasSelectedMyCustomAddress: action.hasSelectedMyCustomAddress, //true or false options,
                hasSelectedOriginStop: true,
                selectedOriginStop: action.selectedStop,
                stopsType: 'destination'
            }
        case tripConstants.SET_SELECTED_STOP_DESTINATION_SUCCESS:
            return {
                ...state,
                dataTripRequest: action.newDataTripRequest,
                hasSelectedMyCustomAddress: action.hasSelectedMyCustomAddress, //true or false options
                selectedDestinationStop: action.selectedStop,
            }
        case tripConstants.CLEAR_SELECTED_STOP_ORIGIN_SUCCESS:
            return {
                ...state,
                stopsType: 'origin',
                selectedOriginStop: null,
                hasSelectedOriginStop: false,
                hasSelectedMyCustomAddress: false,
                dataTripRequest: action.oldDataTripRequest
            }
        case tripConstants.SET_REPEAT_TRIP:
            return {
                ...state,
                repeatDataObject: action.trip,
                repeatTrip: true,
                selectedOriginStop: null,
                hasSelectedOriginStop: false,
                selectedDestinationStop: null,
                openModalReturnTrip: true,
                tripToReturn: action.tripToReturn,
                isReturn: action.isReturn,
            }

        case tripConstants.CLEAN_REPEAT_TRIP:
            return {
                ...state,
                tripToReturn: null,
                openModalReturnTrip: false,
                isReturn: false,
            }
        case tripConstants.CLOSE_RETURN_TRIP_MODAL:
            return {
                ...state,
                openModalReturnTrip: false,
            }
        case tripConstants.SET_MODIFY_TRIP_DATA_SUCCESS:
            return {
                ...state,
                modifyTripData: action.currentTrip,
                isModifyTrip: action.currentTrip != null ? true : false
            }
        case tripConstants.MODIFY_TRIP_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            }
        case tripConstants.MODIFY_TRIP_SUCCESS:
            return {
                ...state,
                modifyNewTrip: action.data,
                trip: action.data,
                modifyPendingAction: true,
                isSubmitting: false,
                showResume: false
            }
        case tripConstants.MODIFY_TRIP_FAILURE:
            return {
                ...state,
                errorModify: action.error,
                isSubmitting: false
            }
        case tripConstants.RESET_MODIFY_DATA_SUCCESS:
            return {
                ...state,
                isModifyTrip: false,
                modifyTripData: null,
                modifyNewTrip: null,
                modifyPendingAction: false,
                errorModify: null,
                isSubmitting: false
            }
        case tripConstants.GET_WEEK_DAYS_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            }
        case tripConstants.GET_WEEK_DAYS_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                weekDays: action.days,
            }
        case tripConstants.GET_WEEK_DAYS_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error,
            }
        case tripConstants.OPEN_MODAL_SHOW_EXPEDITIONS:
            return {
                ...state,
                modalExpeditions: true,
                expeditions: action.expeditions
            }
        case tripConstants.CLOSE_MODAL_SHOW_EXPEDITIONS:
            return {
                ...state,
                modalExpeditions: false
            }
        case tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS:
            action.expeditions.sort((a, b) => a.hour.localeCompare(b.hour))
            return {
                ...state,
                modalSelectScheduledPickUp: true,
                posibleSchedule: action.expeditions
            }
        case tripConstants.CLOSE_MODAL_SELECT_SCHEDULE_PICKUP:
            return {
                ...state,
                modalSelectScheduledPickUp: false,
                posibleSchedule: []
            }
        case tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_FAILURE:
            return {
                ...state,
                posibleSchedule: [],
                stopList: []
            }
        case tripConstants.GET_TRIPS_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.GET_TRIPS_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesTrips: action.results,
            }
        case tripConstants.GET_TRIPS_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case tripConstants.GETBYID_REQUEST:
            return {
                ...state,
                pending: true
            }
        case tripConstants.GETBYID_SUCCESS:
            return {
                ...state,
                pending: false,
                trip: action.trip,
                showResume: true
            }
        case tripConstants.GETBYID_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case tripConstants.REJECT_TRIP_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.REJECT_TRIP_SUCCESS:
            return {
                ...state,
                pending: false,
                trip: null,
                originDistanceTime: null,
                destinationDistanceTime: null,
            }
        case tripConstants.REJECT_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case tripConstants.SET_REQUEST_TRIP_INFO:
            return {
                ...state,
                dropOffLocation: action.object.dropOffLocation,
                pickUpLocation: action.object.pickUpLocation,
                pickUpDate: action.object.requestPickUpStartTime,
                customerToRequest: action.object.customerName,
                quantityLuggage: action.object.luggage,
                numPassengers: action.object.numPassengers,
                isRequestByDropOff: action.object.isRequestByDropOff
            }
        case tripConstants.GET_TRIP_WITH_RETURN_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case tripConstants.GET_TRIP_WITH_RETURN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                results: action.trips,
                previewTrip: action.tripToPreview,
                isReturn: true,
            }
        case tripConstants.GET_TRIP_WITH_RETURN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        case multipleTripConstants.SET_PREVIEW_TRIP:
            return {
                ...state,
                previewTrip: action.trip
            }
        case multipleTripConstants.PROCESS_MULTIPLE_TRIP_SUCCESS: {
            return {
                ...state,
                trip: {
                    ...state.trip,
                    isMultipleRequest: true,
                }
            }
        }
        case tripConstants.CLEAN_TRIP: {
            return {
                ...state,
                trip: null,
                previewTrip: null,
                isReturn: false,
                tripToReturn: null,
                results: []
            }
        }
        default:
            return state;
    }
}