export const getPickUpLocationAddress = (trip) => {
    return {
        desc: {
            description: trip.pickUpLocation.address
        },
        location: {
            address: trip.pickUpLocation.address,
            desc: {
                description: trip.pickUpLocation.address
            },
            lat: trip.pickUpLocation.lat,
            lng: trip.pickUpLocation.lon
        }
    }
}

export const getDropOffLocationAddress = (trip) => {
    return {
        desc: {
            description: trip.dropOffLocation.address
        },
        location: {
            address: trip.dropOffLocation.address,
            desc: {
                description: trip.dropOffLocation.address
            },
            lat: trip.dropOffLocation.lat,
            lng: trip.dropOffLocation.lon
        }
    }
}