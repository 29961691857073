import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';

export const areasServices = {
    getZonesByType
}

async function getZonesByType() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
        },
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/serviceZones/getZones', requestOptions)
        .then(handleResponse)
        .then(
            response => {
                //console.log("response (getZonesByType) => ", response);
                return response;
            }
        )
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || text || response.status;
            return Promise.reject(error);
        }
        return data;
    });
}