import { useCallback, useState } from "react"

export function useSelectTime() {
    const [newTimeToTrip, setNewTimeToTrip] = useState('')

    const setNewTime = useCallback((value) => {
        setNewTimeToTrip(value)
    }, [])

    return {
        newTimeToTrip,
        setNewTime
    }
}