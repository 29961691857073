import { useCallback, useState } from "react";

export function useErrorsMultipleRequestTrip() {
    const [errors, setErrors] = useState([])

    const updateErrors = useCallback((value) => {
        setErrors(value)
    }, [])

    return {
        errors,
        updateErrors
    }
}