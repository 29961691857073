import React, { useRef, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import { termsAndConditions } from './termsAndConditions'

const TermsAndConditionsModal = ({ visible, handleClose, acceptRules }) => {

    const { t } = useTranslation()
    const terms = termsAndConditions

    const [isBottom, setIsBottom] = useState(true)

    const onScroll = (event) => {
        const window = event.target
        const bottom = Math.floor(window.scrollTop) + window.clientHeight === window.scrollHeight

        if (bottom) {
            setIsBottom(false)
        }

    };


    return (
        <Dialog
            open={visible}
            onClose={handleClose}
            fullWidth
            onScroll={(e) => onScroll(e)}
        >
            <DialogTitle>
                <Typography align='center' style={{ fontSize: 25 }}> {t('register.page.tosModal.title')} </Typography>
            </DialogTitle>
            <DialogContent>
                <div dangerouslySetInnerHTML={{ __html: terms }}></div>
            </DialogContent>
            <div style={{ position: 'sticky', padding: 10 }}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isBottom}
                    onClick={() => {
                        acceptRules()
                        handleClose()
                    }}
                    fullWidth
                    size="large"
                >
                    {t('register.page.tosModal.acceptButton')}
                </Button>
            </div>
        </Dialog>
    )
}

export default TermsAndConditionsModal