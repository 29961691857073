import React from 'react';
import Root from './Root';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider } from '@material-ui/core';
import theme from './components/theme';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css'
export default class App extends React.Component {

    render() {
        const notistackRef = React.createRef();
        const onClickDismiss = key => () => {
            notistackRef.current.closeSnackbar(key);
        }
        return (
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider
                            preventDuplicate
                            ref={notistackRef}
                            maxSnack={5}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                            action={(key) => (
                                <CloseIcon onClick={onClickDismiss(key)} />
                            )}
                        >
                            <Root />
                        </SnackbarProvider>
                    </ThemeProvider>
                </Provider>
            </I18nextProvider>
        )
    }
}