import React, { lazy, Suspense } from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';
import { history } from './helpers/history';
import PrivateRoute from './PrivateRoute';
import Home from './components/home';
import Login from './components/login';
import Register from './components/register';
import ResetPassword from './components/resetPassword';
import i18next from 'i18next';
import ResumeTrip from './components/trips/resumeTrip';
import Spinner from './components/shared/spinner';

const Profile = lazy(() => import('./components/profile'))
const Faq = lazy(() => import('./components/faq'))
const Bookings = lazy(() => import('./components/bookings'))

class Root extends React.Component {

    componentDidMount() {
        document.title = i18next.t('dashboard.title')
    }

    render() {
        return (
            <Router history={history} basename={'/'}>
                <Suspense fallback={<Spinner />}>
                    <Switch>
                        {/* <Route path={`${process.env.PUBLIC_URL}/login`} component={Login}></Route> */}
                        <Route path={`/login`} component={Login}></Route>
                        <Route path={`/register`} component={Register}></Route>
                        <Route path={`/resetPassword`} component={ResetPassword}></Route>
                        <Route path={`/resumeTrip`} component={ResumeTrip}></Route>
                        <PrivateRoute path={`/profile`} component={Profile}></PrivateRoute>
                        <PrivateRoute path={`/bookings`} component={Bookings}></PrivateRoute>
                        <Route path={`/es/faq`} component={Faq}></Route>
                        <Route path={`/ca/faq`} component={Faq}></Route>
                        <Route path={`/en/faq`} component={Faq}></Route>
                        <PrivateRoute path={`/home`} component={Home}></PrivateRoute>
                        <Route path="/" render={() => <Redirect to={`/home`} />} />
                    </Switch>
                </Suspense>
            </Router >
        )
    }
}




export default Root