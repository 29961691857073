import React, { useEffect } from 'react';
import Dashboard from '../../dashboard';
import { ResumeTripWrapped } from './details';
import { connect, useDispatch, useSelector } from 'react-redux';
import { tripActions } from '../../../redux/actions/trip_actions';
import { userActions } from '../../../redux/actions/user_actions';
import MultipleBook from '../multipleBook';
import { multipleTripsActions } from '../../../redux/actions/multipleTrips_action';
import AlertDialog from '../../alert-dialog';
import { useTranslation } from 'react-i18next';
import { GoogleApiWrapper } from 'google-maps-react';
import stringConstants from '../../../constants/strings';
import { withTheme } from '@material-ui/core';
import { areasActions } from '../../../redux/actions/areas_actions';
import { trackingActions } from '../../../redux/actions/tracking_actions';
import { regularLine_actions } from '../../../redux/actions/regularLine_actions';
import WaitingScreen from '../../custom/backdrop'
import ReturnTrip from '../returnTrip';

const ResumeTrip = ({ ...props }) => {
    const dispatch = useDispatch()
    const location = props.location
    const { t } = useTranslation()

    useEffect(() => {
        let searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id')
        const originalId = searchParams.get('originalId')
        const withReturn = searchParams.get('withReturn')

        if (id) {
            if (withReturn) {
                dispatch(tripActions.getTripWithReturn(id))
            } else if (originalId) {
                dispatch(multipleTripsActions.getTripsFromMultipleRequest(originalId, id))
            } else {
                dispatch(tripActions.getById(id))
            }
        }

        dispatch(userActions.getCustomerProfile())
        dispatch(tripActions.getWeekDays())
        dispatch(areasActions.getZonesByType())


    }, [dispatch, location.search])

    useEffect(() => {
        return () => {
            dispatch(tripActions.cleanTrip())
            dispatch(multipleTripsActions.cleanMultiTrip())
            dispatch(multipleTripsActions.updateScheduleReturn(false))
        }
    }, [])

    const tripReducer = useSelector((state) => state.tripReducer)
    const customerReducer = useSelector((state) => state.customerReducer)
    const multipleTripReducer = useSelector((state) => state.multipleTripReducer)
    const { modalMessage, message } = useSelector((state) => state.tripReducer)
    return (
        <>
            <Dashboard
                component={
                    <ResumeTripWrapped
                        tripReducer={tripReducer}
                        loading={tripReducer.isSubmitting || tripReducer.trip == null}
                        multipleTripReducer={multipleTripReducer}
                        customerReducer={customerReducer}
                        weekDays={tripReducer.weekDays}
                        dispatch={dispatch}
                        {...props}
                    />
                }
            />
            <ReturnTrip open={tripReducer.openModalReturnTrip} />
            <MultipleBook
                weekDays={tripReducer.weekDays}
            />
            <AlertDialog
                title={""}
                open={modalMessage}
                content={() => message || ''}
                onClickAccept={() => dispatch(tripActions.closeModalRequestTrip())}
            />
            <WaitingScreen
                message={t('form.requestTripForm.sendingRequest')}
                open={tripReducer.isSubmitting || multipleTripReducer.pending}
            />
        </>
    );
}


const actionCreators = {
    clearAllMarkers: tripActions.clearAllMarkers,
    getZonesByType: areasActions.getZonesByType,
    getCustomerProfile: userActions.getCustomerProfile,
    requestTrip: tripActions.requestTrip,
    getTripServices: tripActions.getTripServices,
    setTrip: tripActions.setTrip,
    setTripToAcceptedByUser: tripActions.setTripToAcceptedByUser,
    setTripToRejectedByUser: tripActions.setTripToRejectedByUser,
    handClose: tripActions.selectServiceClose,
    closeModalRequestTrip: tripActions.closeModalRequestTrip,
    selectedStop: tripActions.selectedStop,
    setStops: tripActions.setStops,
    clearOrigin: tripActions.clearOrigin,
    getPositionForTrip: trackingActions.getPositionForTrip,
    cleanRepeatTrip: tripActions.cleanRepeatTrip,
    cancelTripPlanned: multipleTripsActions.cancelTripPlanned,
    cleanTrip: tripActions.cleanTrip,
    rejectTrip: tripActions.rejectTrip,
    modifyTrip: tripActions.modifyTrip,
    acceptModifyTrip: tripActions.acceptModifyTrip,
    repeatTrip: tripActions.repeatTrip,
    getWeekDays: tripActions.getWeekDays,
    closeModalExpeditions: tripActions.closeModalExpeditions,
    openModalExpeditions: tripActions.openModalExpeditions,
    getRegularLinePossibleDropoffs: regularLine_actions.getRegularLinePossibleDropoffs,
    closeModalSelectScheduledPickUp: regularLine_actions.closeModalSelectScheduledPickUp,
    setOriginalOutbound: multipleTripsActions.setOriginalOutbound,
    setOriginalReturn: multipleTripsActions.setOriginalReturn,
}



const a = connect(null, actionCreators)(ResumeTrip)

export default GoogleApiWrapper({
    apiKey: (stringConstants.API_Key_Google),
})(withTheme(a));