export const termsAndConditions = `
<h3>1. IDENTIFICACIÓ</h3>

Responsable del tractament: l’Associació de Municipis per la Mobilitat i el 
Transport Urbà (en endavant “l’AMTU”), empresa amb CIF G62805106, i domicili 
social al Carrer Anselm Clavé, 69-73, 2a planta – 08402 – Granollers
(Barcelona)

<p> CIF : G62805106 </p>
<p> Correu electrònic: dpd@amtu.cat </p>

<h3>2. INFORMACIÓ I CONSENTIMENT</h3>

En acceptar aquesta Política de Privacitat, l'usuari (d'ara endavant, l'"Usuari ") ha
estat degudament informat i ha atorgat el seu consentiment informat, exprés,
lliure i inequívoc per al tractament per part de L’AMTU de les dades personals
recollides a través dels formularis de l'aplicació Flexitransport Catalunya (d'ara 
endavant), l'"App").

L'usuari ha de llegir atentament aquesta Política de Privacitat, que ha estat 
redactada de manera clara i senzilla, per tal d'ajudar-ne la comprensió, i per tal
de determinar lliure i voluntàriament si desitja facilitar les seves dades personals 
a l’AMTU.

<h3>3. OBLIGACIÓ DE FACILITAR LES DADES PERSONALS</h3>

Les dades sol·licitades en els formularis de l'App són, en general, obligatòries 
per tal de complir amb les finalitats indicades (tret que en el camp obligatori
s'especifiqui el contrari). Per tant, si aquests no es presten o no es presten
correctament, no es podrà prestar el servei/demanda requerit, només podent
visualitzar lliurement el contingut de l'App.

<h3>4. AMB QUINA FINALITAT ES TRACTARÀN LES DADES PERSONALS DE L'USUARI I DURANT QUANT TEMPS?</h3>

Les dades personals facilitades a través de l'App seran tractades per l’AMTU, 
d'acord amb les finalitats següents:

1. Gestió de la relació contractual entre l'usuari i l’AMTU.
    <ul>
        <li> Per gestionar el registre de l'Usuari a l'App. </li>
        <li> Per gestionar, tramitar, enviar i fer el seguiment de la contractació dels béns o serveis oferts per l’AMTU. </li>
        <li> Per gestionar el pagament dels béns o serveis contractats per l'Usuari. </li>
        <li> Contactar amb l'Usuari per tal d'ajudar-lo a finalitzar la contractació de béns i serveis, o quan no ha acabat el procés de pagament. </li>
        <li> Contactar i/o determinar la posició de l'Usuari per obtenir el lloc de prestació dels serveis. </li>
        <li> Gestionar, tramitar i donar resposta a les peticions, peticions, incidències o consultes de l'Usuari. </li>
    </ul>

2. Informes interns per millorar els béns, serveis i App de l’AMTU
    <ul>
        <li>Realitzar i analitzar informes sobre el comportament de l'Usuari per millorar l'App. </li>
    </ul>

3. Enquestes de qualitat i satisfacció
    <ul>
        <li>Realitzar i analitzar enquestes de qualitat i satisfacció relacionades amb els béns i serveis prestats per l’AMTU.</li>
    </ul>
4. Comunicacions comercials i promocionals personalitzades
    <ul>
        <li>Enviar comunicacions comercials i promocionals per mitjans telemàtics o convencionals, en relació amb béns i serveis similars als prèviament contractats o adquirits a l’AMTU.</li>
    </ul>

5. Duració del tractament de les dades personals de l’usuari
    <ul>
        <li> Les dades personals de l’usuari es tractaran fins que el propi usuari mantingui el seu compte a l’App. Quan l’usuari es doni de baixa a l’App i elimini el seu perfil, les seves dades seran eliminades de manera permanent. L’usuari sempre podrà exercir el seu dret d’eliminació de les seves dades personals enviant un correu a dpdp@amtu.cat </li>
    </ul>

<h3>5. DADES PERSONALS TRACTADES PER L’AMTU</h3>

L’AMTU tractarà les següents categories de dades d'usuari:

1. Gestió de la relació contractual entre l'usuari i l’AMTU.
    <ul>
        <li> Dades identificatives</li>
        <li> Detalls de contacte</li>
        <li> Dades de característiques personals</li>
        <li> Dades de transacció de béns i serveis</li>
        <li> Dades econòmiques i financeres</li>
        <li> Dades de geolocalització</li>
        <li> Dades de navegació a l'aplicació</li>
    </ul>
2. Informes interns per millorar els béns, serveis i l’App
    <ul>
        <li> Dades identificatives</li>
        <li> Detalls de contacte</li>
        <li> Dades de característiques personals</li>
        <li> Dades de transacció de béns i serveis</li>
        <li> Dades econòmiques i financeres</li>
        <li> Dades de geolocalització</li>
        <li> Dades de navegació a l'aplicació</li>
    </ul>
3. Enquestes de qualitat i satisfacció
    <ul>
        <li>Dades identificatives</li>
        <li>Detalls de contacte</li>
        <li>Dades de transacció de béns i serveis</li>
        <li>Altres dades facilitades per l'Usuari en camps oberts</li>
    </ul>
4. Comunicacions comercials i promocionals personalitzades
    <ul>
        <li> Dades identificatives</li>
        <li> Detalls de contacte</li>
        <li> Dades de transacció de béns i serveis</li>
    </ul>
    
En el cas que l'usuari faciliti dades de tercers, declara comptar amb el 
consentiment dels mateixos i es compromet a cedir la informació continguda a la 
Política de Privacitat, eximint a l’AMTU de qualsevol responsabilitat al 
respecte. No obstant això, l’AMTU podrà realitzar les comprovacions periòdiques
per comprovar aquest fet, adoptant les mesures de diligència deguda 
corresponents, d'acord amb la normativa de protecció de dades.       

<h3>6. LEGITIMITAT DEL TRACTAMENT DE DADES</h3>

Les seves dades seran tractades de la següent manera:
Gestió de la relació contractual entre l'usuari i AMTU
Compliment de la relació contractual.
Informes interns per millorar els béns, serveis i l’App
Interès legítim.
Enquestes de satisfacció per avaluar la qualitat del servei
Interès legítim.
Comunicacions comercials i promocionals personalitzades
El tractament de les dades personals amb aquesta finalitat quan la persona 
usuària es registri a l'App es realitzarà sobre la base d'un interès legítim, tal com
reconeix el Responsable per la normativa.

Aquest interès legítim es basa en l'interès d'oferir als clients de Flexitransport
Catalunya béns i serveis mitjançant recursos de màrqueting directe. 
La retirada del seu consentiment, que pot efectuar en qualsevol moment, podria
impedir que la seva sol·licitud, consulta, suggeriment o queixa es gestioni correctament. 
La retirada del seu consentiment no afectarà la licitud de cap
tractament realitzat abans d'aquesta retirada.

<h3>7. DIVULGACIÓ DE DADES A TERCERS</h3>

Les seves dades personals es poden comunicar a:
    <ul>
        <li> Les autoritats públiques en la mesura que sigui necessari per complir amb la legislació aplicable.</li>
        <li> Tribunals en la mesura que sigui necessari per complir amb la legislació aplicable.</li>
        <li> Les entitats que presten el servei en altres ubicacions diferents a les que l'usuari es va registrar inicialment, si l'usuari sol·licita el servei Flexitransport Catalunya en altres ubicacions o territoris amb serveis disponibles, prenent com a base legitimadora l'execució del contracte.</li>
    </ul>

<h3>8. TRANSFERÈNCIES INTERNACIONALS</h3>

Estan previstes les transferències de dades a tercers països, concretament al 
núvol de Google, amb la finalitat d’allotjar les aplicacions i les bases de 
dades de la plataforma Flexitransport Catalunya:

¿A quins destinataris se li comunicaran les seves dades?

Les seves dades se li comunicaran als següents destinataris:
    <ul>
        <li> Tercers països.</li>
        <li> Google Cloud Platform. Pot consultar la seva política de privacitat i altres aspectes legals a: https://cloud.google.com/terms/cloud-privacy-notice</li>
    </ul>
    
Si l’AMTU decidís realitzar alguna altra transferència internacional de dades, 
l’AMTU obtindrà el consentiment explícit de la persona usuària o regularitzarà la
transferència internacional de dades esmentada d'acord amb la legislació 
aplicable.

<h3>9. CONSERVACIÓ DE DADES PERSONALS</h3>

Les dades personals es conservaran durant el temps que sigui necessari per dur 
a terme les finalitats establertes a la Política de Privacitat, llevat que la legislació
aplicable exigeixi un període de conservació més llarg. En aquest sentit, l’AMTU
conservarà les dades personals de la persona usuària mentre aquesta tingui un
compte a l'App. Per a les dades personals relacionades amb els serveis, l’AMTU
conservarà aquestes dades durant el termini per complir amb les obligacions 
legals.
    
<h3>10. GEOLOCALITZACIÓ</h3>

Les funcionalitats de l'App poden utilitzar la geolocalització del dispositiu on està instal·lada l'App.

L'usuari pot desactivar directament aquesta funció al dispositiu, però aquesta
acció pot inhibir o provocar un funcionament erroni en l'ús d'altres funcionalitats
de l'aplicació.

Els vehicles disposen d'un sistema de geolocalització per facilitar la informació
de l'usuari relacionada amb la prestació del servei. El sistema de geolocalització
integrat a l’App o al vehicle no es pot desconnectar perquè és necessari per a la
prestació dels serveis i la seva seguretat.
       
<h3>11. COMUNICACIONS COMERCIALS I PROMOCIONALS</h3>

Una de les finalitats per a les quals l’AMTU tractarà les dades personals
facilitades pels usuaris és l’enviament de comunicacions electròniques
personalitzades amb informació sobre productes, serveis, esdeveniments,
cursos, programes, promocions i notícies rellevants per als usuaris. Si es realitza
alguna comunicació d'aquest tipus, s'adreçarà exclusivament a aquells usuaris 
que no hagin manifestat prèviament la seva negativa a rebre-les.

Per dur a terme aquesta finalitat, es desenvoluparà un perfil basat en les 
preferències de l'usuari, a partir de la informació facilitada pel mateix o obtinguda
fins i tot de fonts externes.

En cas que l'usuari desitgi deixar de rebre comunicacions comercials o 
promocionals, podrà donar-se de baixa d'aquests serveis enviant un correu
electrònic a la següent adreça de correu electrònic: info@flexitransport.cat o a
través de qualsevol de les comunicacions rebudes.

<h3>12. RESPONSABILITAT DE L'USUARI</h3>

L'usuari:
    <ul>
        <li> Garanteix que té més de 18 anys i que les dades que facilita a l'AMTU són certes, exactes, completes i actualitzades. En aquest sentit, l'Usuari és responsable de la veracitat de totes les dades que comuniqui i facilitarà informació actualitzada, de manera que respongui a la seva situació real. </li>
        <li> Garanteix que ha informat a terceres persones les dades de les quals es faciliten, si n'hi ha, dels aspectes continguts en aquest document. També garanteix que ha obtingut la seva autorització per facilitar les seves dades a l'AMTU per a les finalitats indicades. </li>
        <li> Serà responsable de la informació falsa o inexacta facilitada a través de l'App i dels anys directes o indirectes que es puguin ocasionar a l'AMTU o a tercers. </li>
    </ul>

<h3>13. EXERCICI DE DRETS</h3>

L'usuari pot enviar un correu electrònic a dpd@amtu.cat, adjuntant una fotocòpia
del seu document d'identitat, amb les següents finalitats:
    <ul>
        <li> Revocar els consentiments atorgats.</li>
        <li> Obtenir confirmació de si L’AMTU està tractant dades personals relacionades amb l'Usuari o no.</li>
        <li> Accedir a les seves dades personals.</li>
        <li> Rectificar les dades inexactes, incompletes o que cal actualitzar.</li>
        <li> Sol·licitar la supressió de les seves dades quan, entre altres motius, les dades ja no siguin necessàries.</li>
        <li> Els fins per als quals es van recollir en primer lloc.</li>
        <li> Obtenir de l’AMTU la limitació del tractament de les dades, si es compleix alguna de les condicions estipulades en el reglament de protecció de dades.</li>
        <li> Sol·licitar la portabilitat de les seves dades.</li>
        <li> Així mateix, l'usuari podrà presentar una reclamació davant l'Autoritat Espanyola de Protecció de Dades, si ho considera</li>
        <li> Comunicar a l’AMTU que ha vulnerat els drets reconeguts en la normativa de protecció de dades aplicable.</li>
    </ul>

No obstant això, per l'esmentat anteriorment, l'usuari podrà contactar amb el
delegat de protecció de dades de l’AMTU enviant un correu electrònic a la
següent adreça: dpdp@amtu.cat

<h3>14. MESURES DE SEGURETAT</h3>

Les dades de l'usuari seran tractades en tot moment de manera absolutament
confidencial i guardant l'obligatori deure de confidencialitat, d'acord amb el que
estableix la normativa aplicable, adoptant les mesures tècniques i organitzatives
necessàries per garantir la seguretat de les seves dades i evitar la seva alteració,
manipulació, pèrdua, tractament o accés no autoritzat, tenint en compte l'estat
de la tècnica, la naturalesa de les dades emmagatzemades i els riscos als quals 
estan exposats.
`