import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    //shadows: Array(25).fill('0.5'),
    spacing: 0,
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            //main: '#386e9d',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            main: '#26A5CF',
            contrastText: '#FFFFFF'
        },
        secondary: {
            //light: '#0066ff',
            //main: 'rgb(220,20,60)',//'#25282A',
            // dark: will be calculated from palette.secondary.main,
            //contrastText: 'rgb(240, 243, 245)'//'#ffcc00',
            main: '#FFFFFF',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#00BFB3',
        },
        white: {
            main: '#FFFFFF',
        },
        black: {
            main: '#000000'
        },
        grey: {
            main: '#E0E0E0'
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: 'Montserrat',
        fontSize: 12.5
    }
});

export default theme;