import { useCallback, useState } from "react";

export function useModal() {

    const [open, setOpen] = useState(false)

    const closeModal = useCallback(() => {
        setOpen(false)
    }, [open])

    const openModal = useCallback(() => {
        setOpen(true)
    }, [open])

    const handleModal = useCallback(() => {
        setOpen((prevValue) => !prevValue)
    }, [open])

    return {
        open,
        closeModal,
        handleModal,
        openModal
    }

}