import React from 'react';
import HomeView from './page';
import Dashboard from '../dashboard/index';
import { tripActions } from '../../redux/actions/trip_actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { userActions } from '../../redux/actions/user_actions';
import { areasActions } from '../../redux/actions/areas_actions';
import { trackingActions } from '../../redux/actions/tracking_actions';
import { regularLine_actions } from '../../redux/actions/regularLine_actions';

class Home extends React.Component {

    componentDidMount() {
        this.props.getZonesByType();
        this.props.getCustomerProfile();
        this.props.getWeekDays()
    }

    render() {
        return (
            <Dashboard
                component={
                    <HomeView {...this.props} />
                }
            />
        );
    }
}
function mapState(state) {
    const { customerReducer, tripReducer, areasReducer, trackingReducer, multipleTripReducer } = state;
    return { customerReducer, tripReducer, areasReducer, trackingReducer, multipleTripReducer };
}

const actionCreators = {
    getZonesByType: areasActions.getZonesByType,
    getCustomerProfile: userActions.getCustomerProfile,
    requestTrip: tripActions.requestTrip,
    getTripServices: tripActions.getTripServices,
    setTrip: tripActions.setTrip,
    setTripToAcceptedByUser: tripActions.setTripToAcceptedByUser,
    setTripToRejectedByUser: tripActions.setTripToRejectedByUser,
    handClose: tripActions.selectServiceClose,
    closeModalRequestTrip: tripActions.closeModalRequestTrip,
    selectedStop: tripActions.selectedStop,
    setStops: tripActions.setStops,
    clearOrigin: tripActions.clearOrigin,
    getPositionForTrip: trackingActions.getPositionForTrip,
    cleanRepeatTrip: tripActions.cleanRepeatTrip,
    cleanTrip: tripActions.cleanTrip,
    modifyTrip: tripActions.modifyTrip,
    acceptModifyTrip: tripActions.acceptModifyTrip,
    repeatTrip: tripActions.repeatTrip,
    getWeekDays: tripActions.getWeekDays,
    closeModalExpeditions: tripActions.closeModalExpeditions,
    openModalExpeditions: tripActions.openModalExpeditions,
    getRegularLinePossibleDropoffs: regularLine_actions.getRegularLinePossibleDropoffs,
    closeModalSelectScheduledPickUp: regularLine_actions.closeModalSelectScheduledPickUp
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Home);