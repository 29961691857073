import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import "./styles.css";

const customStyles = {
    content: {
        overflow: 'hidden'
    },
    actionsContent: {
        paddingBottom: 25,
        paddingTop: 15
    },
    acceptButton: {
        marginLeft: 20,
        marginRight: 20
    }
};

class AlertDialog extends React.Component {

    render() {
        const { t } = this.props
        const Content = this.props.content
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClickCancel ?? this.props.onClose}
                    maxWidth={this.props.maxWidth ?? null}
                    fullWidth={this.props.fullWidth ?? null}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={customStyles}
                >
                    {this.props.title && (
                        <DialogTitle id="alert-dialog-title" disableTypography>
                            <Typography variant='h6'><strong>{this.props.title}</strong></Typography>
                        </DialogTitle>
                    )}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                        {
                            this.props.content ?
                                <Content /> : null
                        }

                    </DialogContent>
                    <DialogActions style={customStyles.actionsContent}>
                        {
                            this.props.onClickCancel ?
                                <Button
                                    onClick={this.props.onClickCancel}
                                    disabled={this.props.conditionDisableCancel ?? false}
                                    variant="text"
                                    size="small"
                                    color="primary">
                                    {t('alert-dialog.cancel')}
                                </Button>
                                : null
                        }

                        <Button
                            onClick={this.props.onClickAccept}
                            disabled={this.props.conditionDisableAccept ?? false}
                            style={customStyles.acceptButton}
                            variant="contained"
                            size="small"
                            color="primary"
                            autoFocus>
                            {t('alert-dialog.accept')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AlertDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClickCancel: PropTypes.func,
    onClickAccept: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired

}

export default withTranslation('common')(AlertDialog);