import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inside: {
    justifyContent: 'center',
    alignSelf: 'center',
    flexDirection: 'row'
  }
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  //const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.open}>
          <CircularProgress color="inherit" />
          <h1 style={{ marginLeft: 15 }}>
            {props.message}
          </h1>
      </Backdrop>
    </div>
  );
}