import stringConstants from "../constants/strings";
import { authHeader } from "../helpers/authHeader";

export const trackingServices = {
    getPositionForTrip
};

function getPositionForTrip(vehicleId, pickUpDate, dropOffDate) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader(),
        }
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/tracking/v1/LastPosition/getVehicleTripPosition?idVehicle=' + vehicleId + '&pickupDateTime=' + pickUpDate + '&dropoffDateTime=' + dropOffDate, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || text || response.status;
            return Promise.reject(error);
        }
        return data;
    });
}