import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({

    container: {

        width: 400
    }

}))

export default useStyles