import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import LockIcon from '@material-ui/icons/Lock';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import React from 'react';
import PropTypes from 'prop-types';
import { blue, grey } from '@material-ui/core/colors';
import { zoneStopTypes } from '../../../constants/zoneStopTypes';
import i18next from 'i18next';
import { alertActions } from '../../../redux/actions/alert_actions';
import store from '../../../redux/store';
import { factory } from '../../../helpers/factory';
import { SERVICES_TYPE } from '../../../constants/types';

class ModalSelectServices extends React.Component {

    handleListItemClick = (service) => {
        const { tripReducer, t } = this.props
        let data = tripReducer.dataTripRequest;
        if (service.isRestricted) {
            let lang = i18next.language
            store.dispatch(alertActions.warning(Object.values(service['restrictionMessage' + (lang.charAt(0).toUpperCase() + lang.slice(1))])))
            return;
        }
        this.props.handClose();
        if (service.serviceType != SERVICES_TYPE.regularService) {
            if (service.originZone.zoneType == zoneStopTypes.WithStops || service.originZone.zoneType == zoneStopTypes.MixedOriginOrDestination || service.originZone.zoneType == zoneStopTypes.MixedOriginAndDestination) {
                //Mostrar parades origen
                this.props.setStops(service, 'origin', service.originZone.zoneStops, data);
            } else if (service.destinationZone.zoneType == zoneStopTypes.WithStops || service.originZone.zoneType == zoneStopTypes.MixedOriginOrDestination || service.originZone.zoneType == zoneStopTypes.MixedOriginAndDestination) {
                //Mostrar parades destí
                this.props.setStops(service, 'destination', service.destinationZone.zoneStops, data);
            } else {
                if (data != null && service != null) {
                    const obj = {
                        origin: data.origin,
                        originAddress: data.originAddress,
                        destination: data.destination,
                        destinationAddress: data.destinationAddress,
                        numPassengers: data.numPassengers,
                        requestPickUpStartTime: data.requestPickUpStartTime,
                        customerName: data.customerName,
                        hasWheelChair: data.hasWheelChair,
                        babyCart: data.babyCart,
                        luggage: data.luggage,
                        serviceId: service.id,
                        isOnRelatedCustomerBehalf: data.isOnRelatedCustomerBehalf,
                        relatedCustomer: data.relatedCustomer
                    }
                    let trip = factory.createTripRequest(obj)
                    this.props.requestTrip(trip, service.id);
                } else {
                    alert(t('form.modalSelectedServices.errorOccurs'));
                }
            }
        } else {
            let origins = []
            service.serviceLine.outbound.zoneStops.forEach(function (element, i) {
                element.outbound = true
                element.outboundIndex = i
                element.allowOppositeDirections = service.serviceLine.outbound.allowOppositeDirections
                origins.push(element)
            });
            service.serviceLine.return.zoneStops.forEach(function (element, i) {
                element.return = true
                element.returnIndex = i
                element.allowOppositeDirections = service.serviceLine.return.allowOppositeDirections
                origins.push(element)
            });
            this.props.setStops(service, 'origin', origins, data);
        }

    };

    render() {
        const { tripReducer, t } = this.props
        return (
            <React.Fragment>
                <Dialog onClose={this.props.handClose} aria-labelledby="simple-dialog-title" open={this.props.open}>
                    <DialogTitle>{t('form.modalSelectedServices.availableServices')}</DialogTitle>
                    <List>
                        {tripReducer.services.map((item) => (
                            <ListItem button onClick={() => this.handleListItemClick(item)} key={item.id}>
                                <ListItemAvatar>
                                    <Avatar style={{
                                        backgroundColor: blue[100],
                                        color: blue[600],
                                    }}>
                                        {
                                            item.serviceType == 20 || item.serviceType == 30 ?
                                                <LocalTaxiIcon />
                                                :
                                                <AirportShuttleIcon />
                                        }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.name} />
                                {item.isRestricted && (
                                    <Avatar style={{
                                        backgroundColor: grey[100],
                                        color: grey[600],
                                    }}>
                                        <LockIcon />
                                    </Avatar>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Dialog>
            </React.Fragment>
        );
    }
}

ModalSelectServices.propTypes = {
    handClose: PropTypes.func.isRequired,
    //t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default ModalSelectServices;