import React from 'react';
import MaterialTable from 'material-table'

class UserTariffTypology extends React.Component {

    constructor(props) {
        super()
        this.state = {
            tariff: props.tariff
        }
    }

    convertObjectToArray() {
        const keys = Object.keys(this.state.tariff)
        const values = Object.values(this.state.tariff)
        let result = []
        keys.forEach(function (key, index) {
            result.push(
                { 'countPassenger': keys[index], 'price': values[index] }
            )
        })
        return result
    }

    render() {
        const { t } = this.props
        const translations = t('material_table.tableStrings', { returnObjects: true })
        return (
            <React.Fragment>
                {/* {
                    this.props.error?.message.length > 0 ?
                        <FormLabel error>
                            {this.props.error.message}
                        </FormLabel>
                        : null
                } */}
                <MaterialTable
                    title={t('userTariff.typology.item.priceCount')}
                    localization={translations}
                    columns={[
                        {
                            title: t('userTariff.typology.item.countPassenger'),
                            field: 'countPassenger',
                            type: 'numeric',
                            cellStyle: {
                                textAlign: "center"
                            }
                        },
                        {
                            title: t('userTariff.typology.item.price'),
                            field: 'price',
                            cellStyle: {
                                textAlign: "center"
                            },
                            render: (rowData) => { return rowData.price + '€' }
                        }
                    ]}
                    data={this.convertObjectToArray(this.state.tariff)}
                    options={{
                        actionsColumnIndex: 2
                    }}
                    editable={false}
                />
            </React.Fragment>
        );
    }
}

export default UserTariffTypology;