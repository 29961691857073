import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    root: {
        ' & .MuiFormControl-root': {
            width: '100%',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        paddingTop: '0%', //10%,
        marginBottom: theme.spacing(3),
    },
    container: {
        //backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%'
    },
    linkRulesRegister: {
        textDecoration: 'none',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    button: {
        marginTop: theme.spacing(5),
    }
}));

export default useStyles;