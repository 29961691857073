import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userService } from '../src/services/userService';
import stringConstants from './constants/strings';
import { userActions } from './redux/actions/user_actions';
import store from './redux/store';


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        userService.getWithExpiry('user_customer')
            && userService.getRoleByClaims().then(
                result => {
                    if (result.userRole == 20) { //CustomerUser
                        return true
                    }
                    else {
                        store.dispatch(userActions.logout())
                        return false
                    }
                },
                error => {
                    store.dispatch(userActions.logout())
                    return false
                }
            )
            ? <Component {...props} />
            : <Redirect to={`/login`} />
    )} />
)

export default PrivateRoute;

