export const SERVICES_TYPE = {
    regularService: 10,
    tadService: 20,
    assignmentWheelService: 30,
    regularWithSchedule: 40
}

export const REGULAR_SERVICES_TYPES = [
    SERVICES_TYPE.regularService.toString(),
    SERVICES_TYPE.regularService,
    SERVICES_TYPE.regularWithSchedule.toString(),
    SERVICES_TYPE.regularWithSchedule,

]

export const ZONES_STOPS_TYPE = {
    /// The zone has fixed stops
    withStops: 10,
    /// The zone is open. User can select any point within the zone
    withoutStops: 20,
    /// The zone has fixed stops and user can select any point within a zone in either origin or destination
    mixedOriginOrDestination: 30,
    /// The zone has fixed stops and user can select any point within a zone in both origin and destination
    mixedOriginAndDestination: 40
}

export const TripStatus = {
    /// Default status when trip reaches back end
    /// </summary>
    Open: 10,
    Pending: 20,
    /// <summary>
    /// Routing Algorithm returns no solution
    /// </summary>
    NotAvailable: 30,
    /// <summary>
    /// Routing Algorithm returns solution
    /// </summary>
    Available: 40,
    /// <summary>
    /// User accepts trip
    /// </summary>
    AcceptedByUser: 50,
    /// <summary>
    /// Customer service operator accepts trip
    /// </summary>
    AcceptedByCustomerService: 51,
    /// <summary>
    /// Driver accepts trip
    /// </summary>
    AcceptedByDriver: 52,
    /// <summary>
    /// Customer accepts trip using the IVR phone service
    /// </summary>
    AcceptedByIVR: 53,
    /// <summary>
    /// Third party customer accepts trip
    /// </summary>
    AcceptedByThirdParty: 54,
    /// <summary>
    /// User rejects trip instead of accepting
    /// </summary>
    RejectedByUser: 60,
    /// <summary>
    /// Customer service operator rejects trip instead of accepting
    /// </summary>
    RejectedByCustomerService: 62,
    /// <summary>
    /// BackgroundService rejects available trip after n minutes
    /// </summary>
    RejectedByBackgroundService: 63,
    /// <summary>
    /// All drivers of assignment wheel rejects the trip, then this trip has to be assigned by opuser
    /// </summary>
    RejectedByAllDrivers: 64,
    /// <summary>
    /// IVR customer rejects trip instead of accepting
    /// </summary>
    RejectedByIVR: 65,
    /// <summary>
    /// Wheel assignment trip has no driver available to manage the request.
    /// </summary>
    NoDriversAvailable: 66,
    /// <summary>
    /// Third Party customer rejects trip instead of accepting
    /// </summary>
    RejectedByThirdParty: 67,
    /// <summary>
    /// User cancels trip after accepting
    /// </summary>
    CancelledByUser: 70,
    /// <summary>
    /// Customer service operator cancels trip after accepting
    /// </summary>
    CancelledByCustomerService: 71,
    /// <summary>
    /// Trip was cancelled because it was modified with different parameters
    /// </summary>
    CancelledForModification: 72,
    /// <summary>
    /// Customer service operator cancels trip with assignment wheel after not finding a suitable driver
    /// </summary>
    CancelledByCustomerServiceWheel: 73,
    /// <summary>
    /// Third party customer cancels trip  after accepting
    /// </summary>
    CancelledByThirdParty: 74,
    /// <summary>
    /// Customer is enjoying a trip
    /// </summary>
    Ongoing: 80,
    /// <summary>
    /// Driver detects that passenger is not in the stop
    /// </summary>
    NonAttendance: 90,
    /// <summary>
    /// Trip has not performed
    /// </summary>
    NotPerformed: 91,
    /// <summary>
    /// Customer has finished a trip
    /// </summary>
    Finished: 100,
    /// <summary>
    ///The trip has been rejected by all drivers and then assigned by the operator manually
    /// </summary>
    DriverAssignedByOperator: 110,
    /// <summary>
    ///The trip has been compacted with a previous trip/trips already assigned to a driver
    /// </summary>
    DriverAssignedAfterCompaction: 111
}