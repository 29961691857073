import { trackingConstants } from '../../constants/tracking_constants';
import { trackingServices } from '../../services/trackingServices';

export const trackingActions = {
    getPositionForTrip
}

function getPositionForTrip(vehicleId, pickUpDate, dropOffDate) {
    return dispatch => {
        dispatch(request())
        trackingServices.getPositionForTrip(vehicleId, pickUpDate, dropOffDate).then(
            position => {
                dispatch(success(position))
                localStorage.setItem('vehiclePosition', JSON.stringify(position))
            },
            error => {
                dispatch(failure(error))
            }
        )

    }

    function request() {
        return { type: trackingConstants.GET_POSITION_TRIP_REQUEST }
    }

    function success(position) {
        return { type: trackingConstants.GET_POSITION_TRIP_SUCCESS, position }
    }

    function failure(error) {
        return { type: trackingConstants.GET_POSITION_TRIP_FAILURE, error }
    }
}