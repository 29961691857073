export const trackingConstants = {
    GET_ALL_LOCATIONS_REQUEST: 'GET_ALL_LOCATIONS_REQUEST',
    GET_ALL_LOCATIONS_SUCCESS: 'GET_ALL_LOCATIONS_SUCCESS',
    GET_ALL_LOCATIONS_FAILURE: 'GET_ALL_LOCATIONS_FAILURE',

    GET_LAST_LOCATION_REQUEST: 'GET_LAST_LOCATION_REQUEST',
    GET_LAST_LOCATION_SUCCESS: 'GET_LAST_LOCATION_SUCCESS',
    GET_LAST_LOCATION_FAILURE: 'GET_LAST_LOCATION_FAILURE',

    GET_POSITION_TRIP_REQUEST: 'GET_POSITION_TRIP_REQUEST',
    GET_POSITION_TRIP_SUCCESS: 'GET_POSITION_TRIP_SUCCESS',
    GET_POSITION_TRIP_FAILURE: 'GET_POSITION_TRIP_FAILURE',
};