import i18next from 'i18next';
import moment from 'moment'
import store from '../redux/store';

export const factory = {
    createCustomerRegistration,
    createEditCustomer,
    createCustomer,
    createLogin,
    createTripRequest,
    createModifyTripRequest,
    formatDate,
    parseJwt,
    createGetTripServices,
    createUpdateRequestForm,
    createItemMultipleTripResult
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


function createCustomer(name, surname1, surname2, identityDocumentCode, email, phoneNumber, isPMR, hasWheelChair, birthDate, prefix, countryCode) {
    return {
        "name": name,
        "fullSurname": surname1 + ' ' + surname2,
        "surname1": surname1,
        "surname2": surname2,
        "identityDocumentCode": identityDocumentCode,
        "email": email,
        "phoneNumber": phoneNumber,
        "isPMR": isPMR,
        "hasWheelChair": hasWheelChair,
        "birthDate": birthDate,
        "prefix": prefix,
        "countryCode": countryCode,
    }
}


function createCustomerRegistration(email, password, firstName, surname1, document, gdprAccepted, birthDate, phoneNumber, prefix, countryCode) {
    return {
        "email": email,
        "password": password,
        "name": firstName,
        "surname1": surname1,
        "identityDocumentCode": document,
        "gdprAccepted": gdprAccepted,
        "birthDate": moment(birthDate),
        "phoneNumber": phoneNumber,
        "lang": "ca",
        "prefix": prefix,
        "countryCode": countryCode,
    }
}

function createEditCustomer(email, firstName, lastName, lastName2, phoneNumber, ID, isPMR, hasWheelChair, related, birthDate, addressPosition, favouriteAddresses, prefix, countryCode) {
    return {
        "email": email,
        "name": firstName,
        "surname1": lastName,
        "surname2": lastName2,
        "birthDate": birthDate,//moment(birthDate).utc().format('YYYY-MM-DD'),
        "phoneNumber": phoneNumber,
        "identityDocumentCode": ID,
        "addressPosition": addressPosition,
        "isPMR": isPMR,
        "hasWheelChair": hasWheelChair,
        "relatedCustomers": related,
        "favouriteAddresses": favouriteAddresses,
        "prefix": prefix,
        "countryCode": countryCode,
    }
}


function createLogin(loginId, password) {
    return {
        "email": loginId,
        "password": password,
        "returnSecureToken": true
    }
}

function createTripRequest({ origin, originAddress, destination, destinationAddress, isRequestByDropOff, originalTripId, outboundTripId, isMultipleRequest, numPassengers, requestPickUpStartTime, customerName, hasWheelChair, babyCart, luggage, serviceId, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, serviceLineDirection = null, idStopP, idStopD, isReturn = false }) {
    let lang = i18next.language;
    if (store.getState().customerReducer.currentUser != null) {
        lang = store.getState().customerReducer.currentUser.language;
    }
    const res = {
        'pickUpLocation': {
            'lat': origin.position.lat, /*41.4894712,*/
            'lon': origin.position.lng, /*2.29463,*/
            'address': originAddress,
            'id': idStopP?.replace('myCustomAddress', '')
        },
        'dropOffLocation': {
            'lat': destination.position.lat, /*41.4894012,*/
            'lon': destination.position.lng, /*2.29461,*/
            'address': destinationAddress,
            'id': idStopD?.replace('myCustomAddress', '')
        },
        'requestPickUpStartTime': requestPickUpStartTime,
        'numPassengers': numPassengers,
        'customerName': customerName,
        'hasWheelChair': hasWheelChair,
        'luggage': luggage,
        "serviceId": serviceId,
        "customerDateOfBirth": customerDateOfBirth,
        "serviceName": serviceName,
        "customerNotificationLanguage": lang,
        "isOnRelatedCustomerBehalf": isOnRelatedCustomerBehalf || false,
        "relatedCustomer": relatedCustomer || null,
        "serviceLineDirection": serviceLineDirection ? capitalizeFirstLetter(serviceLineDirection) : '',
        "isMultipleRequest": isMultipleRequest,
        "isRequestByDropOff": isRequestByDropOff,
        "isReturn": isReturn
    }

    if (originalTripId) {
        res.originalTripId = originalTripId
    }

    if (outboundTripId) {
        res.outboundTripId = outboundTripId
    }

    return res
}

function createModifyTripRequest({ origin, destination, isRequestByDropOff, numPassengers, requestPickUpStartTime, customerName, hasWheelChair, /*babyCart,*/ luggage, pushToken, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, passengerId }) {
    let lang = i18next.language;
    if (store.getState().customerReducer.currentUser != null) {
        lang = store.getState().customerReducer.currentUser.language;
    }
    return {
        'pickUpLocation': origin,
        'dropOffLocation': destination,
        'requestPickUpStartTime': moment(requestPickUpStartTime).utc().format('YYYY-MM-DD HH:mm'),
        'numPassengers': numPassengers,
        'customerName': customerName,
        'hasWheelChair': hasWheelChair,
        'luggage': luggage, //integer
        //"customerExpoPushToken": pushToken,
        "customerDateOfBirth": customerDateOfBirth,
        "serviceName": serviceName,
        "customerNotificationLanguage": lang,
        "isOnRelatedCustomerBehalf": isOnRelatedCustomerBehalf || false,
        "relatedCustomer": relatedCustomer || null,
        "passengerId": passengerId,
        "isRequestByDropOff": isRequestByDropOff
    }
}

function formatDate(date, format) {
    let minDate = moment.utc("0001-01-01"); // minimum value as per UTC

    let receiveDate = moment(date);
    if (moment.utc(receiveDate).isAfter(minDate)) {
        return receiveDate.format(format)
    } else {
        return ""
    }
}


function createGetTripServices(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff) {
    return {
        ...createGetTripServicesBase(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff),
        pickUpLocation: { ...trip.dropOffLocation },
        dropOffLocation: { ...trip.pickUpLocation },
    }
}

function createGetTripServicesBase(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff) {
    const newMoment = moment(newTime, 'HH:mm')
    return {
        customerId: trip.customerId.slice(),
        requestPickUpStartTime: moment(trip.requestPickUpStartTime).set({ hours: newMoment.hours(), minutes: newMoment.minutes() }).toDate(),
        numPassengers: trip.numPassengers,
        customerExpoPushToken: pushToken.slice(),
        customerNotificationLanguage: null,
        luggage: trip.luggage,
        hasWheelChair: trip.hasWheelChair,
        customerDateOfBirth: customerDateOfBirth.slice(),
        customerName: trip.customerName?.slice(),
        isOnRelatedCustomerBehalf: trip.isOnRelatedCustomerBehalf,
        relatedCustomer: trip.relatedCustomerId?.slice(),
        isReturn: isReturn,
        outboundTripId: outboundTripId,
        isMultipleRequest: isMultipleRequest,
        originalTripId: trip.originalTripId,
        isRequestByDropOff: isRequestByDropOff,
    }
}

function createUpdateRequestForm(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff) {
    return {
        ...createGetTripServicesBase(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff),
        pickUpLocation: {
            desc: {
                description: trip.pickUpLocation.address
            },
            location: { lat: trip.pickUpLocation.lat, lon: trip.pickUpLocation.lon }
        },
        dropOffLocation: {
            desc: {
                description: trip.dropOffLocation.address
            },
            location: { lat: trip.dropOffLocation.lat, lon: trip.dropOffLocation.lon }
        }
    }
}

function createItemMultipleTripResult(trip) {
    return {
        apiErrors: [],
        hasErrors: false,
        pickupHours: [],
        requestPickUpTime: trip.requestPickUpStartTime,
        tripResponse: trip
    }
}