import React from 'react';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Box, Typography, Link, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useSnackbar } from "notistack";
import useStyles from './styles';
import utils from '../../helpers/validations'
import store from '../../redux/store';
import { alertActions } from '../../redux/actions/alert_actions';
import stringConstants from '../../constants/strings'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.amtu.cat/">
                AMTU
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'} - v{stringConstants.version}
        </Typography>
    );
}



class LoginView extends React.Component {

    constructor(props) {
        super();
        this.state = {
            email: '',
            password: '',
            remember: false,
            errors: {
                email: { result: false, message: '' },
                password: { result: true, message: '' }
                //password: { result: false, message: '' }
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onResendEmailVerification = this.onResendEmailVerification.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        let errors = this.state.errors;
        errors[name].message = "";
        this.setState({ [name]: value });
    }

    validateForm() {
        let errors = this.state.errors;
        errors.email = utils.validateEmail(this.state.email)
        //errors.password = utils.validatePassword(this.state.password)
        this.setState({ errors })
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.validateForm()
        if (this.isFormValid()) {
            this.props.login(this.state.email, this.state.password, this.state.remember)
        }
    }

    onResendEmailVerification() {
        if (this.props.authenticationReducer.infoVerify) {
            store.dispatch(alertActions.clear());
        }
    }

    isFormValid() {
        let valid = true;
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result)
                valid = false
        });
        return valid
    }

    render() {
        const classes = this.props.styles
        const { t, alertReducer } = this.props
        const lastAlert = alertReducer.messages.length > 0 ? alertReducer.messages[alertReducer.messages.length - 1] : null
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('login.page.form.title')}
                    </Typography>
                    <form className={classes.form} autoComplete="off" onSubmit={this.onSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            value={this.state.email}
                            helperText={this.state.errors.email.message}
                            error={this.state.errors.email.message.length === 0 ? false : true}
                            label={t('login.page.form.email')}
                            onChange={this.handleChange}
                            name="email"
                            type="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={this.state.password}
                            helperText={this.state.errors.password.message}
                            error={this.state.errors.password.message.length === 0 ? false : true}
                            label={t('login.page.form.password')}
                            onChange={this.handleChange}
                            type="password"
                            id="password"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.remember}
                                    onChange={() => this.setState({ remember: !this.state.remember })}
                                    color="primary"
                                />
                            }
                            label={t('login.page.form.remember')}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {t('login.page.form.button')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href={`${process.env.PUBLIC_URL}/resetPassword`} variant="body2">
                                    {t('login.page.form.resetPassword')}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={`${process.env.PUBLIC_URL}/register`} variant="body2">
                                    {t('login.page.form.register')}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
                {lastAlert && this.props.enqueueSnackbar(lastAlert.message, { variant: lastAlert.type, autoHideDuration: 9000, onClose: this.props.handleCloseAlert }).toString().substring(0, 0)}
            </Container>
        );
    }
}

function Hook(props) {
    const classes = useStyles();
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.clear();
    }
    const { enqueueSnackbar } = useSnackbar()
    return <LoginView
        styles={classes}
        handleCloseAlert={handleCloseAlert}
        enqueueSnackbar={enqueueSnackbar}
        {...props}
    />
}

export default Hook;