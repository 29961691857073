import { useState } from 'react'

export function useSelectRequestType() {
    const [isRequestByDropOff, setIsRequestByDropOff] = useState(false)

    const onChangeRequestType = (value) => {
        setIsRequestByDropOff(value)
    }

    return {
        isRequestByDropOff,
        onChangeRequestType
    }
}