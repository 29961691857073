import { userConstants } from '../../constants/user_constants';

let initialState = {
    pending: false,
    isSubmitting: false,
    pendingSendRelatedCustomers: false,
    isSubmittingGDPR: false,
    customerAddresses: [],
    relatedCustomers: [],
};

export function customerReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.GETBYID_REQUEST:
            return {
                ...state,
                pending: true
            };
        case userConstants.GETBYID_SUCCESS:
            return {
                ...state,
                pending: false,
                loaded: true,
                relatedCustomers: action.data.relatedCustomers || [],
                currentUser: action.data
            };
        case userConstants.GETBYID_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case userConstants.ADD_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: [...state.relatedCustomers, action.customer],
                pendingSendRelatedCustomers: true,
            };
        case userConstants.REMOVE_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.filter(item => item.tableData.id != action.customer.tableData.id)
            };
        case userConstants.EDIT_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.map(
                    (content, i) => action.customer.tableData.id === content.tableData.id ? { ...action.customer }
                        : content
                ),
                pendingSendRelatedCustomers: true
            };
        case userConstants.OPEN_MODAL:
            return {
                ...state,
                add: action.typeOperation === "add" ? true : false,
                edit: action.typeOperation === "edit" ? true : false,
                userEdit: action.typeOperation === "edit" ? action.user : null
            };
        case userConstants.SETUSER_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                error: null,
            }
        case userConstants.SETUSER_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                error: null,
                pendingSendRelatedCustomers: false
            }
        case userConstants.SETUSER_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error
            }
        case userConstants.GET_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isSubmittingGDPR: true,
            }
        case userConstants.GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isSubmittingGDPR: false,
                GDPRurlFile: action.data
            }
        case userConstants.GET_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isSubmittingGDPR: false,
                error: action.error
            }
        case userConstants.CUSTOMER_ADDRESSES_SUCCESS:
            return {
                ...state,
                customerAddresses: action.results
            }
        default:
            return state
    }
}