import React from 'react';
import { Grid, withTheme } from '@material-ui/core';
import Spinner from '../shared/spinner';
import ResumeTrip from '../trips/resumeTrip';
import RequestTripForm from '../trips/form/requestTrip';
import CancelIcon from '@material-ui/icons/Cancel';
import ModalSelectPosibleSchedule from '../custom/modalSelectPosibleSchedule';
import { GoogleApiWrapper } from 'google-maps-react';
import stringConstants from '../../constants/strings';
import MapView from '../shared/map';
class HomeView extends React.Component {
    constructor(props) {
        super();
        this._isMounted = false;
        this.state = {
            pickupTime: null, //moment().format("YYYY-MM-DD[T]HH:mm"),
            passengers: 1,
            luggage: 0,
            wheelchair: false,
            babyCart: false,
            region: null,
            destinationAddress: '',
            originAddress: '',
            ticked: false,
            clear: false,
            distanceString: '',
            bounds: null,
            markers: [],
        }

        this.child = React.createRef();
    }


    componentDidMount() {
        this.props.getCustomerProfile();
    }

    componentWillUnmount() {
        this.props.cleanRepeatTrip();
    }



    render() {
        const { t, customerReducer, tripReducer, areasReducer } = this.props
        if (customerReducer.currentUser == undefined) {
            return <Spinner
                loading={true}
            />
        }
        return (

            <Grid container spacing={1} >
                {this.props.tripReducer.trip == null ?
                    <>
                        <Grid item md={4}>
                            <RequestTripForm
                                {...this.props}
                                setMarkers={(markers) => this.setState({ markers: markers })}
                                setBounds={(bounds) => this.setState({ bounds: bounds })}
                                editable={tripReducer.isModifyTrip ? false : !tripReducer.showStops ? true : true}
                                originStop={this.state.originStop}
                                destinationStop={this.state.destinationStop}
                                ref={this.child}
                            />
                        </Grid>
                        <Grid item md={8}>
                            {tripReducer.hasSelectedOriginStop && tripReducer.showStops ?
                                <Grid item md={12} style={{ padding: 5 }}>
                                    <h3>{t('home.originSelected')} : {tripReducer.selectedOriginStop.name} <CancelIcon style={{ cursor: 'pointer' }} onClick={() => this.clearSelectedOriginStop()} /></h3>
                                </Grid>
                                : null}
                            <div style={{ position: 'relative', width: '100%', height: '80vh', zIndex: 0 }}>
                                <MapView
                                    t={t}
                                    google={this.props.google}
                                    tripReducer={tripReducer}
                                    areasReducer={areasReducer}
                                    markers={this.state.markers}
                                    setMarkers={(markers) => this.setState({ markers: markers })}
                                    theme={this.props.theme}
                                    getCustomerProfile={this.props.getCustomerProfile}
                                    clearOrigin={this.props.clearOrigin}
                                    addTripAddress={this.props.addTripAddress}
                                    bounds={this.state.bounds}
                                    setBounds={(bounds) => this.setState({ bounds: bounds })}
                                    {...this.props}
                                />
                            </div>
                        </Grid>
                    </>
                    :
                    <ResumeTrip
                        {...this.props}
                    />
                }

                <ModalSelectPosibleSchedule
                    open={tripReducer.modalSelectScheduledPickUp ?? false}
                    expeditions={tripReducer.posibleSchedule}
                    tripReducer={tripReducer}
                    markers={this.state.markers}
                    insert={this.props.requestTrip}
                    closeModalSelectScheduledPickUp={this.props.closeModalSelectScheduledPickUp}
                />
            </Grid>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (stringConstants.API_Key_Google),
})(withTheme(HomeView));
