import { alertConstants } from '../../constants/alert_constants';


export const alertActions = {
    success,
    error,
    clear,
    warning,
    info
};

function success(msg) {
    const message = {
        type: 'success',
        message: msg
    }
    return { type: alertConstants.SUCCESS, message };
}

function error(msg) {
    const message = {
        type: 'error',
        message: msg
    }
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR };
}

function warning(msg) {
    const message = {
        type: 'warning',
        message: msg
    }

    return { type: alertConstants.WARNING, message };
}

function info(msg) {
    const message = {
        type: 'info',
        message: msg
    }
    return { type: alertConstants.INFO, message };
}