import React from 'react'

export const ArrowBack = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_529_767)">
            <path d="M11.0001 1.8335C13.4068 1.8316 15.7177 2.77625 17.4338 4.46351C19.15 6.15077 20.1338 8.44528 20.1728 10.8516C20.2118 13.258 19.3029 15.5832 17.6423 17.3252C15.9816 19.0671 13.7026 20.0862 11.2971 20.1623L11.0001 20.1668L10.7031 20.1623C8.29761 20.0862 6.01854 19.0671 4.35793 17.3252C2.69731 15.5832 1.78838 13.258 1.82738 10.8516C1.86637 8.44528 2.85016 6.15077 4.56634 4.46351C6.28252 2.77625 8.59341 1.8316 11.0001 1.8335ZM11.6482 6.68542C11.4763 6.51357 11.2432 6.41703 11.0001 6.41703C10.757 6.41703 10.5239 6.51357 10.352 6.68542L6.68534 10.3521C6.65828 10.3793 6.63288 10.408 6.60926 10.4382L6.55059 10.5226L6.50292 10.6124L6.46259 10.7132L6.43509 10.8159L6.41951 10.9314L6.41676 11.0002L6.42043 11.0827L6.42684 11.1358L6.44976 11.244L6.48184 11.3402L6.53134 11.4438L6.57076 11.508L6.63584 11.5951L6.68534 11.6482L10.352 15.3149L10.4382 15.391C10.6224 15.5335 10.854 15.6004 11.0858 15.5784C11.3177 15.5563 11.5324 15.4468 11.6864 15.2721C11.8405 15.0974 11.9222 14.8706 11.9151 14.6379C11.908 14.4051 11.8126 14.1837 11.6482 14.0187L9.54718 11.9168H14.6668L14.774 11.9104C15.006 11.8828 15.2187 11.7677 15.3687 11.5885C15.5186 11.4093 15.5945 11.1797 15.5808 10.9464C15.5671 10.7132 15.4649 10.494 15.295 10.3336C15.1251 10.1732 14.9004 10.0838 14.6668 10.0835H9.54626L11.6482 7.98158L11.7243 7.89542C11.8611 7.71905 11.9288 7.49882 11.9148 7.27604C11.9008 7.05327 11.806 6.84326 11.6482 6.68542Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_529_767">
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>

}