import { trackingConstants } from "../../constants/tracking_constants";


let initialState = {
    isSubmitting: false,
    position: null,
    error: null
};

export function trackingReducer(state = initialState, action) {
    switch (action.type) {
        case trackingConstants.GET_POSITION_TRIP_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            }
        case trackingConstants.GET_POSITION_TRIP_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                position: action.position
            }
        case trackingConstants.GET_POSITION_TRIP_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error
            }
        default:
            return state;
    }
}