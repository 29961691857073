import { userConstants } from '../../constants/user_constants';

let initialState = { auth: false, pending: false, refreshSuccess: false, isSubmitting: false};

export function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            }
        case userConstants.REGISTER_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                data: action.data,
            }
        case userConstants.REGISTER_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error
            }
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                pending: true,
                auth: false
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                auth: true,
                refreshToken: action.data.user.refreshToken,
                user: action.data.user,
                pending: false
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                auth: false,
                pending: false,
                error: action.error,
                infoVerify: action.data
            };
        case userConstants.TOKEN_REQUEST:
            return {
                ...state,
                pending: true
            }
        case userConstants.TOKEN_SUCCESS:
            return {
                ...state,
                pending: false,
                token: action.data.token,
                customerId: action.data.claims.mongo_id,
                roles: action.data.claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
            };
        case userConstants.TOKEN_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case userConstants.LOGOUT:
            return {
                auth: false,
                pending: false,
                refreshToken: null,
                user: null
            };
        case userConstants.REFRESH_REQUEST:
            return {
                ...state,
                pending: true
            };
        case userConstants.REFRESH_SUCCESS:
            return {
                ...state,
                pending: false,
                refreshSuccess: true,
                token: action.token
            };
        case userConstants.REFRESH_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        default:
            return state
    }
}