import areasConstants from '../../constants/areas_constants';

let initialState = { isSubmitting: false, areas: null, error: null, arrayGeofencing: null};

export function areasReducer(state = initialState, action) {
    switch (action.type) {
        case areasConstants.GET_ZONES_BY_TYPE_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            }
        case areasConstants.GET_ZONES_BY_TYPE_SUCCESS:
            return {
                ...state,
                areas: action.data,
                arrayGeofencing: action.array,
                isSubmitting: false,
                error: null
            }
        case areasConstants.GET_ZONES_BY_TYPE_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                error: action.error
            }
        default:
            return state;
    }
}