export function authHeader() {
    // return authorization header with jwt token
    function getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        let date = new Date();
        let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        const now = new Date(now_utc)
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }
    const token = getWithExpiry('token_customer');
    //return { 'Authorization': 'Bearer ' + token };
    return 'Bearer ' + token;
}

// export const Test = () => {
//     return tripReducer.bookings;
// }
// function mapState(state) {
//     const { customerReducer, tripReducer } = state;
//     return { customerReducer, tripReducer };
// }

// const actionCreators = {
//     getTripsFromCustomer: tripActions.getTripsFromCustomer,
//     setTrip: tripActions.setTrip
// }

// export default connect(mapState, null)(Test);