import { Dialog, DialogContent, DialogTitle, Grid, Hidden, IconButton } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import GridExpedition from './gridExpeditions';

class ModalExpeditions extends React.Component {

    render() {
        const { t, tripReducer } = this.props
        return (
            <React.Fragment>
                <Dialog onClose={this.props.handClose} aria-labelledby="simple-dialog-title" maxWidth={'lg'} fullWidth open={this.props.open}>
                    <DialogTitle>{t('form.modalExpeditions.expeditions')}
                        {this.props.handClose ? (
                            <IconButton
                                aria-label="close"
                                onClick={this.props.handClose}
                                style={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent style={{ height: '350px' }}>
                        <Grid container spacing={3}>
                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <GridExpedition
                                readOnly={true}
                                {...this.props}
                                entries={tripReducer.expeditions}
                            />

                        </Grid>

                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

}

ModalExpeditions.propTypes = {
    handClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default ModalExpeditions;